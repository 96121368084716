import React, { useState } from 'react';
import customAxios from '../../../../../utils/customaxios';

const CsvUploader = () => {
  const [file, setFile] = useState(null); // Estado para o arquivo CSV
  const [tableName, setTableName] = useState(''); // Estado para o nome da tabela
  const [message, setMessage] = useState(''); // Estado para mensagens de sucesso ou erro

  // Função para manipular a mudança de arquivo
  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Define o arquivo CSV selecionado
  };

  // Função para manipular a mudança do nome da tabela
  const handleTableNameChange = (event) => {
    setTableName(event.target.value); // Define o nome da tabela
  };

  // Função para enviar o arquivo CSV
  const handleUpload = async () => {
    if (!file || !tableName) {
      setMessage('Please provide both a CSV file and a table name.');
      return;
    }

    const formData = new FormData(); // Cria um objeto FormData para enviar o arquivo e o nome da tabela
    formData.append('file', file); // Adiciona o arquivo ao FormData
    formData.append('tableName', tableName); // Adiciona o nome da tabela ao FormData

    try {
      const response = await customAxios.post('https://client.rapidchargeback.com/api/app/table/upload/csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Define o cabeçalho para o tipo de dado enviado
        },
      });

      setMessage(response.data.message); // Exibe mensagem de sucesso
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file. Please check the console for more details.'); // Exibe mensagem de erro
    }
  };

  return (
    <div>
      <h2>Upload CSV to Database</h2>
      <input 
        type="text" 
        placeholder="Enter table name" 
        value={tableName} 
        onChange={handleTableNameChange} 
      />
      <br />
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <br />
      <button onClick={handleUpload}>Upload CSV</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default CsvUploader;
