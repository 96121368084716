import React, { useState, useEffect } from 'react';
import styles from './database.module.css';
import Header from '../../components/panel/panel';
import customAxios from '../../../../utils/customaxios'

const App = () => {
  const [tableName, setTableName] = useState('');
  const [oldTableName, setOldTableName] = useState('');
  const [newTableName, setNewTableName] = useState('');
  const [columnName, setColumnName] = useState('');
  const [newColumnName, setNewColumnName] = useState('');
  const [columnType, setColumnType] = useState('');
  const [message, setMessage] = useState('');
  const [tables, setTables] = useState({});
  const [selectedTable, setSelectedTable] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Função para buscar todas as tabelas e colunas
  const fetchTablesAndColumns = async () => {
    try {
      const response = await customAxios.get('https://client.rapidchargeback.com/api/app/table/list');
      setTables(response.data);
    } catch (error) {
      setMessage('Erro ao buscar tabelas e colunas.');
      // console.error(error);
    }
  };

  useEffect(() => {
    fetchTablesAndColumns();
  }, []);

  // Função para criar uma nova tabela
  const createTable = async () => {
    try {
      const response = await customAxios.post('https://client.rapidchargeback.com/api/app/table/create', {
        tableName,
      });
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após criar
    } catch (error) {
      setMessage('Erro ao criar tabela.');
      // console.error(error);
    }
  };

  // Função para renomear uma tabela
  const renameTable = async () => {
    try {
      const response = await customAxios.post('https://client.rapidchargeback.com/api/app/table/rename', {
        oldTableName,
        newTableName,
      });
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após renomear
    } catch (error) {
      setMessage('Erro ao renomear tabela.');
      // console.error(error);
    }
  };

  // Função para deletar uma tabela
  const deleteTable = async (name) => {
    try {
      const response = await customAxios.delete(`https://client.rapidchargeback.com/api/app/table/${name}`);
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após deletar
    } catch (error) {
      setMessage('Erro ao deletar tabela.');
      // console.error(error);
    }
  };

  // Função para criar uma nova coluna
  const createColumn = async () => {
    try {
      const response = await customAxios.post(`https://client.rapidchargeback.com/api/app/table/${selectedTable}/columns/create`, {
        columnName,
        columnType,
      });
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após criar coluna
    } catch (error) {
      setMessage('Erro ao criar coluna.');
      // console.error(error);
    }
  };

  // Função para renomear uma coluna
  const renameColumn = async () => {
    try {
      const response = await customAxios.post(`https://client.rapidchargeback.com/api/app/table/${selectedTable}/columns/rename`, {
        oldColumnName: columnName,
        newColumnName,
      });
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após renomear coluna
    } catch (error) {
      setMessage('Erro ao renomear coluna.');
      // console.error(error);
    }
  };

  // Função para deletar uma coluna
  const deleteColumn = async () => {
    try {
      const response = await customAxios.post(`https://client.rapidchargeback.com/api/app/table/${selectedTable}/columns/delete`, {
        columnName,
      });
      setMessage(response.data.message);
      fetchTablesAndColumns(); // Atualizar lista após deletar coluna
    } catch (error) {
      setMessage('Erro ao deletar coluna.');
      // console.error(error);
    }
  };

  // Abrir modal e definir tabela selecionada
  const handleEditTable = (table) => {
    setSelectedTable(table);
    setShowModal(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content_header}>
        <Header
          title="Banco de dados"
          description="Painel de gestão para administrar tabelas e colunas."
        />
      </div>

      {/* Listar tabelas e botão de editar */}
      <div className={styles.list}>
        <h3>Listagem de Tabelas</h3>
        {Object.keys(tables).map((table) => (
          <div key={table} className={styles.tableItem}>
            <p>{table}</p>
            <button onClick={() => handleEditTable(table)}>Editar</button>
            {/*<button onClick={() => deleteTable(table)}>Deletar</button>*/}
          </div>
        ))}
      </div>

      {/* Formulário para criar tabela */}
      <div className={styles.form}>
        <h3>Criar Tabela</h3>
        <input
          type="text"
          placeholder="Nome da Tabela"
          value={tableName}
          onChange={(e) => setTableName(e.target.value)}
        />
        <button onClick={createTable}>Criar Tabela</button>
      </div>

      {/* Formulário para renomear tabela */}
      <div className={styles.form}>
        <h3>Renomear Tabela</h3>
        <input
          type="text"
          placeholder="Nome da Tabela Atual"
          value={oldTableName}
          onChange={(e) => setOldTableName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Novo Nome da Tabela"
          value={newTableName}
          onChange={(e) => setNewTableName(e.target.value)}
        />
        <button onClick={renameTable}>Renomear Tabela</button>
      </div>

            {/* Modal para editar colunas */}
            {showModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Editar Colunas de {selectedTable}</h3>
            {/* Listagem de colunas da tabela selecionada */}
            {tables[selectedTable]?.columns.map((column) => (
              <div key={column.Field} className={styles.columnItem}>
                {/* Exibir o nome da coluna e o tipo */}
                <p>{column.Field} <span className={styles.columnType}>({column.Type})</span></p>
                <button style={{ userSelect: 'none' }} onClick={() => { setColumnName(column.Field); deleteColumn(); }}>Deletar</button>
              </div>
            ))}

            {/* Formulário para criar coluna */}
            <div className={styles.form}>
              <input
                type="text"
                placeholder="Nome da Coluna"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Tipo da Coluna (ex: VARCHAR(255))"
                value={columnType}
                onChange={(e) => setColumnType(e.target.value)}
              />
              <button onClick={createColumn}>Criar Coluna</button>
            </div>

            {/* Formulário para renomear coluna */}
            <div className={styles.form}>
              <input
                type="text"
                placeholder="Nome da Coluna Atual"
                value={columnName}
                onChange={(e) => setColumnName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Novo Nome da Coluna"
                value={newColumnName}
                onChange={(e) => setNewColumnName(e.target.value)}
              />
              <button onClick={renameColumn}>Renomear Coluna</button>
            </div>

            <button onClick={() => setShowModal(false)}>Fechar</button>
          </div>
        </div>
      )}

      {/* Mostrar mensagens de resposta */}
      {message && <p className={styles.message}>{message}</p>}
    </div>
  );
};

export default App;
