import React, { useState } from 'react';
import styles from './dashboard.module.css';
import Header from '../../components/panel/panel';
import DashboardVisa from './components/visa';
import DashboardMastercard from './components/mastercard';
import Select from '../../components/select/select';
import Message from '../../components/notice/notice'

const App = () => {
  const [selectedOption, setSelectedOption] = useState('Visa'); // Estado para controlar a opção selecionada

  // Opções para o componente Select
  const options = [
    { value: 'Visa', label: 'Visa' },
    { value: 'Mastercard', label: 'Mastercard' },
  ];

  // Função para lidar com a mudança de seleção
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className={styles.container}>
      <Message
        title="Notas:"
        description="Todos os alertas são registrados e exibidos com base na data e hora em UTC (Tempo Universal Coordenado). O faturamento é calculado considerando essa mesma referência. Ao buscar alertas ou revisar faturas, ajuste seu entendimento para o horário UTC."
      />
      <div className={styles.content_header}>
        <Header
          title="Dashboard"
          description="Dados essenciais em um painel único para otimizar sua operação."
        />
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectChange}
          name="paymentMethod"
          borderRadius="5px"
          maxWidth="150px"
        />
      </div>
      <div>
        {selectedOption === 'Visa' ? <DashboardVisa /> : <DashboardMastercard />}
      </div>
    </div>
  );
};

export default App;
