import axios from 'axios';

// Criando uma instância personalizada do Axios
const customAxios = axios.create();

// Configurar o Axios personalizado para adicionar o token de autenticação em cada requisição
customAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Obter o token do localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default customAxios;
