import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoicePDF from './pdf'; // Importe o componente que você criou para o PDF
import { LuLoader2 } from 'react-icons/lu';
import styles from './components.module.css';

const DownloadInvoiceButton = ({ invoiceData, text = 'Baixar fatura' }) => {

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const [year, month, day] = dateStr.split('-');
    return `${day}/${month}/${year}`; // Remonta a data no formato 'DD/MM/YYYY'
  };  

  return (
    <PDFDownloadLink
        document={<InvoicePDF invoiceData={invoiceData} />} // Use o componente InvoicePDF passando os dados necessários
        fileName={`Alert_${(invoiceData.companyName || 'Empresa').split(' ').slice(0, 3).join('_')}_${formatDate(invoiceData.startDate || '').replace(/\//g, '-')}.pdf`}
        style={{
        textDecoration: 'none',
        color: 'inherit',
        }}
    >
        {({ loading }) => (
        loading ? (
            <LuLoader2 className={styles.loadingIcon} size={20} />
        ) : (
            text // Renderiza o texto ou ícone diretamente
        )
        )}
    </PDFDownloadLink>
  );
};

export default DownloadInvoiceButton;
