import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from "./register.module.css";
import Logo from '../../../../../images/platform/Logo.svg';
import { TbArrowBackUp } from "react-icons/tb";

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [erroMensagem, setErroMensagem] = useState('');
  const formRef = useRef(null);

  // Definindo a chave de acesso padrão e o código de referência a partir da URL
  const key = 'X345UY'; // Chave de acesso fixa
  const [referralCode, setReferralCode] = useState('');

  useEffect(() => {
    // Extrair código de referência da URL se existir
    const params = new URLSearchParams(window.location.search);
    const rdcbrc = params.get('rdcbrc');
    if (rdcbrc) {
      setReferralCode(rdcbrc);
    }
  }, []);

  // Função para formatar o telefone
  const formatTelephone = (value) => {
    if (!value) return value;

    // Remover todos os caracteres que não são números
    const phoneNumber = value.replace(/[^\d]/g, '');

    // Adicionar formatação
    if (phoneNumber.length <= 2) {
      return `+${phoneNumber}`;
    } else if (phoneNumber.length <= 4) {
      return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2)}`;
    } else if (phoneNumber.length <= 9) {
      return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 4)}) ${phoneNumber.slice(4)}`;
    } else {
      return `+${phoneNumber.slice(0, 2)} (${phoneNumber.slice(2, 4)}) ${phoneNumber.slice(4, 9)}-${phoneNumber.slice(9, 13)}`;
    }
  };

  const handleTelephoneChange = (e) => {
    const formattedPhone = formatTelephone(e.target.value);
    setTelephone(formattedPhone);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Verificação simples se os campos estão preenchidos
    if (!name || !email || !password || !telephone) {
      setErroMensagem('Preencha todos os campos obrigatórios.');
      return;
    }

    try {
      const response = await axios.post(
        'https://client.rapidchargeback.com/api/app/auth/register',
        { name, email, password, telephone, key, referralCode },
        {
          headers: {
            'Authorization': 'FHYU8H4PRAPID2023CHARGEBACKFHYU8H4P'
          }
        }
      );

      /*console.log('Registro bem-sucedido:', response.data);*/
      navigate('/app/chargebackalert/dashboard'); // Navegar para o dashboard após o registro

    } catch (error) {
      /*console.error('Erro ao registrar:', error);
      console.error('Detalhes do erro:', error.response || error.message || error);*/

      if (error.response && error.response.status === 400) {
        setErroMensagem('Conta já cadastrada ou número de telefone já existe.');
      } else if (error.response && error.response.status === 403) {
        setErroMensagem('Key de acesso inválida.');
      } else {
        setErroMensagem('Erro ao registrar, tente novamente mais tarde.');
      }

      // Limpar mensagem de erro após 5 segundos
      setTimeout(() => {
        setErroMensagem('');
      }, 5000);
    }
  };

  return (
    <div className={styles.Container}>
      <div className={styles.ContentLeft}>
        <div>
          <img draggable='false' src={Logo} alt="Logo" />
          <h1>Proteção <strong>avançada</strong> contra fraudes online.</h1>
          <div/>
        </div>
      </div>
      <div className={styles.ContentRight}>
        {erroMensagem && <p className={styles.errocontent}>{erroMensagem}</p>}
        <div title='Voltar' className={styles.Return} onClick={() => navigate('/')}>
          <TbArrowBackUp size={30} />
        </div>
        <img draggable= 'false' src={Logo} alt="Logo" />
        <h1>Proteção <strong>avançada</strong> contra fraudes online.</h1>
        <div className={styles.FormModal}>
          <h4>Register</h4>
          <form id='formRegister' ref={formRef} onSubmit={handleRegister}>
            <div className={styles.Line}>
              <p>Nome</p>
              <input 
                type="text" 
                required 
                placeholder="Digite seu nome" 
                onChange={(e) => setName(e.target.value)} 
                value={name} 
              />
            </div>
            <div className={styles.Line}>
              <p>E-mail</p>
              <input 
                type="email" 
                required 
                placeholder="Digite seu e-mail aqui" 
                onChange={(e) => setEmail(e.target.value)} 
                value={email} 
              />
            </div>
            <div className={styles.Line}>
              <p>Senha</p>
              <input 
                type="password" 
                required 
                placeholder="Digite sua senha aqui" 
                onChange={(e) => setPassword(e.target.value)} 
                value={password} 
              />
            </div>
            <div className={styles.Line}>
              <p>Telefone</p>
              <input 
                type="text" 
                required 
                placeholder="Digite seu telefone" 
                onChange={handleTelephoneChange} 
                value={telephone} 
              />
            </div>
          </form>
          <div>
            <button form="formRegister" type="submit" className={styles.buttonregister}>Register</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
