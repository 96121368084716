import React, { useState } from 'react';
import styles from './form.module.css';

const App = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={styles.Form}>
      <h3 className={styles.titleblack}>Aumente suas vendas e eficiência com garantia</h3>
      <p className={styles.paragraphblacklight}>
        Explore como nossas soluções podem catalisar a transformação em sua empresa. Escolha a solução ideal para você e entre em contato com um especialista.
      </p>
      <p className={styles.paragraphblack}>
        😊 Não se preocupe, nós nunca enviaremos spam, prometemos!
      </p>
      <div className={styles.formcontent}>
        <form id="formcontato" action="https://formsubmit.co/contato@rapidchargeback.com" method="POST">
          <div>
            <input style={{ width: '100%' }} type="text" name="Nome" required placeholder="Nome" />
            <input style={{ width: '100%' }} type="email" name="Email" required placeholder="E-mail" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="Telefone" required placeholder="Telefone" />
            <input style={{ width: '100%' }} type="text" name="Nome da Empresa" required placeholder="Nome da empresa" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="Departamento" required placeholder="Departamento" />
            <input style={{ width: '100%' }} type="text" name="Cargo" required placeholder="Cargo" />
          </div>
          <div>
            <input style={{ width: '100%' }} type="text" name="CNPJ" required placeholder="CNPJ" />
            <input style={{ width: '100%' }} type="text" name="Faturamento" required placeholder="Faturamento" />
          </div>
          <input type="text" name="Como chegou até a Rapid?" required placeholder="Como chegou até a Rapid?" />
          <input type="hidden" name="_subject" value="Olá, envio este email por meio da página da RapidChargeback." />
          <input type="hidden" name="_next" value="https://rapidchargeback.com/" />
          <input type="hidden" name="_captcha" value="false" />
          <input type="hidden" name="_template" value="box" />
          <label className={styles.checkboxlabel}>
            <input
              type="checkbox"
              className={styles.checkboxinput}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className={styles.checkboxcustom}></span>
            <p style={{ textAlign: 'start' }} className={styles.paragraphblacklight}>
              Eu li e aceito a <strong>política de privacidade</strong>
            </p>
          </label>
        </form>
        <button form="formcontato" className={styles.formbutton} style={{ marginTop: '40px' }} type="submit">
          Enviar
        </button>
      </div>
    </div>
  );
};

export default App;