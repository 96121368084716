import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const App = ({ allowedRoles, children }) => {
  const checkToken = () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return { isAuthorized: false, redirectTo: '/app/auth/login' };
      }

      const decodedToken = jwtDecode(token);

      // Verifica se o token expirou
      const currentTime = Date.now() / 1000; // Obtém o tempo atual em segundos
      if (decodedToken.exp < currentTime) {
        localStorage.removeItem('token'); // Remove o token expirado
        return { isAuthorized: false, redirectTo: '/app/auth/login' };
      }

      if (!decodedToken || !decodedToken.role) {
        return { isAuthorized: false, redirectTo: '/app/auth/login' };
      }

      if (!allowedRoles.includes(decodedToken.role)) {
        return { isAuthorized: false, redirectTo: '/app/chargebackalert/dashboard' };
      }

      return { isAuthorized: true };
    } catch (error) {
      return { isAuthorized: false, redirectTo: '/app/auth/login' };
    }
  };

  const { isAuthorized, redirectTo } = checkToken();

  if (!isAuthorized) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};

export default App;