import React, { useState, useEffect, useRef } from 'react';
import styles from './datapicker.module.css';
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { useTheme } from '../themecontext/ThemeContext';
import { LuCalendarSearch } from "react-icons/lu";

const App = ({ onDateChange }) => {
  // Definindo "today" como o momento atual em UTC (mantendo a data correta)
  const today = new Date(); // Data atual
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds())); // Cria uma data UTC

  // Definindo "sevenDaysAgo" como 7 dias antes da data atual em UTC
  const sevenDaysAgo = new Date(todayUTC.getTime() - 30 * 24 * 60 * 60 * 1000); // Subtrai 7 dias em milissegundos

  // Usando getUTC para manipular o mês e o ano
  const [currentMonth, setCurrentMonth] = useState(todayUTC.getUTCMonth());
  const [currentYear, setCurrentYear] = useState(todayUTC.getUTCFullYear());
  const [startDate, setStartDate] = useState(sevenDaysAgo);
  const [endDate, setEndDate] = useState(todayUTC);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isPeriodSelectOpen, setIsPeriodSelectOpen] = useState(false);
  const [viewMode, setViewMode] = useState('days'); // 'days', 'months', 'years'
  const { theme } = useTheme();
  const calendarRef = useRef(null);
  const dateInputRef = useRef(null);
  const periodSelectRef = useRef(null);
  const [customDays, setCustomDays] = useState(''); // Novo estado para o input de dias personalizados


  // useEffect para enviar as datas padrão ao montar o componente
  useEffect(() => {
    onDateChange(formatDate(sevenDaysAgo), formatDate(today));
  }, []); // Executa apenas na montagem inicial

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target) &&
        dateInputRef.current &&
        !dateInputRef.current.contains(event.target)
      ) {
        setIsCalendarOpen(false);
        handleApplyClick(); // Aplica o filtro automaticamente ao fechar o calendário
      }
    };
  
    if (isCalendarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCalendarOpen, startDate, endDate]);  // Adicione startDate e endDate como dependências

  useEffect(() => {
    const handleClickOutsidePeriodSelect = (event) => {
      if (
        periodSelectRef.current &&
        !periodSelectRef.current.contains(event.target) &&
        !event.target.classList.contains(styles.navButton) // Evita fechar ao clicar no ícone
      ) {
        setIsPeriodSelectOpen(false);
      }
    };
  
    if (isPeriodSelectOpen) {
      document.addEventListener('mousedown', handleClickOutsidePeriodSelect);
    } else {
      document.removeEventListener('mousedown', handleClickOutsidePeriodSelect);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePeriodSelect);
    };
  }, [isPeriodSelectOpen]);  

  useEffect(() => {
    if (!isPeriodSelectOpen) {
      setCustomDays(''); 
    }
  }, [isPeriodSelectOpen]);

  const formatDate = (date) => date.toISOString().split('T')[0]; // yyyy-mm-dd
  const formatDisplayDate = (date) => {
    if (!date) return 'Data inválida'; // Verifica se a data é nula ou vazia
  
    // Formata a data para o fuso horário UTC
    const utcDate = date.toLocaleDateString('pt-BR', { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric', 
      timeZone: 'UTC' 
    });
  
    return utcDate;
  };  

  const handleDateClick = (day) => {
    const selectedDate = new Date(Date.UTC(currentYear, currentMonth, day)); // Cria a data em UTC
  
    if (!startDate || (startDate && endDate)) {
      setStartDate(selectedDate);
      setEndDate(null);
    } else if (startDate && !endDate) {
      if (selectedDate >= startDate) {
        setEndDate(selectedDate);
      } else {
        setStartDate(selectedDate);
      }
    }
  };  

  const handleApplyClick = () => {
    if (startDate && endDate) {
      onDateChange(formatDate(startDate), formatDate(endDate));
      setIsCalendarOpen(false);
    }
  };

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  const renderCalendarDays = () => {
    const days = [];
    const totalDays = daysInMonth(currentMonth, currentYear);
    const firstDayOfMonth = getFirstDayOfMonth(currentMonth, currentYear);
  
    // Dias do mês anterior a serem exibidos (dias vazios)
    const previousMonthDays = firstDayOfMonth;
    const previousMonthLastDay = daysInMonth(
      currentMonth - 1 < 0 ? 11 : currentMonth - 1,
      currentMonth === 0 ? currentYear - 1 : currentYear
    );
  
    // Adiciona dias do mês anterior
    for (let i = previousMonthDays - 1; i >= 0; i--) {
      const day = previousMonthLastDay - i;
      const date = new Date(Date.UTC(currentYear, currentMonth - 1, day)); // Data do mês anterior em UTC
  
      days.push(
        <div key={`prev-${day}`} className={`${styles.day} ${styles.inactiveDay}`}>
          {date.getUTCDate()} {/* Exibe o dia sem zero à esquerda */}
        </div>
      );
    }
  
    // Adiciona dias do mês atual
    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(Date.UTC(currentYear, currentMonth, day)); // Data do mês atual em UTC
  
      const isSelected =
        (startDate && date.toISOString().split('T')[0] === startDate.toISOString().split('T')[0]) ||
        (endDate && date.toISOString().split('T')[0] === endDate.toISOString().split('T')[0]);
      const isInRange =
        startDate && endDate && date > startDate && date < endDate;
  
      days.push(
        <div
          key={day}
          className={`${styles.day} ${isSelected ? styles.selectedDay : ''} ${isInRange ? styles.inRangeDay : ''}`}
          onClick={() => handleDateClick(day)}
        >
          {date.getUTCDate()} {/* Exibe o dia sem zero à esquerda */}
        </div>
      );
    }
  
    // Dias do próximo mês a serem exibidos para completar as 6 semanas
    const remainingDays = 42 - days.length; // 42 quadrados no total para garantir 6 linhas de semanas
    for (let i = 1; i <= remainingDays; i++) {
      const date = new Date(Date.UTC(currentYear, currentMonth + 1, i)); // Data do próximo mês em UTC
  
      days.push(
        <div key={`next-${i}`} className={`${styles.day} ${styles.inactiveDay}`}>
          {date.getUTCDate()} {/* Exibe o dia sem zero à esquerda */}
        </div>
      );
    }
  
    return days;
  };  

  const renderMonthPicker = () => {
    return (
      <div className={styles.monthPicker}>
        {Array.from({ length: 12 }).map((_, i) => (
          <div key={i} className={styles.month} onClick={() => handleMonthChange(i)}>
            {new Date(0, i).toLocaleString('default', { month: 'long' })}
          </div>
        ))}
      </div>
    );
  };

  const renderYearPicker = () => {
    const startYear = today.getFullYear();
    return (
      <div className={styles.yearPicker}>
        {Array.from({ length: 15 }).map((_, i) => (
          <div key={i} className={styles.year} onClick={() => handleYearChange(startYear - i)}>
            {startYear - i}
          </div>
        ))}
      </div>
    );
  };

  const handleMonthChange = (month) => {
    setCurrentMonth(month);
    setViewMode('days'); // Volta para a visão de dias
  };

  const handleYearChange = (year) => {
    setCurrentYear(year);
    setViewMode('days'); // Volta para a visão de dias
  };

  const handleMonthYearClick = (mode) => {
    setViewMode(mode);
  };

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  // Definição de opções de período
  const periodOptions = [
    { label: 'Hoje', value: 1 },
    { label: 'Últimos 7 dias', value: 7 },
    { label: 'Últimos 14 dias', value: 14 },
    { label: 'Últimos 30 dias', value: 30 },
    { label: 'Últimos 60 dias', value: 60 },
    { label: 'Últimos 90 dias', value: 90 },
  ];

  const handlePeriodSelect = (days) => {
    const newStartDate = new Date(today);
    newStartDate.setDate(today.getDate() - days + 1); // Define a nova data de início
    setStartDate(newStartDate);
    setEndDate(today);
    // Não feche o seletor de períodos aqui
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div
        onClick={() => {
          if (isCalendarOpen) {
            setIsCalendarOpen(false);
            handleApplyClick(); // Aplica o filtro quando o calendário fecha
          } else {
            setIsCalendarOpen(true); // Abre o calendário quando estiver fechado
          }
        }}
        className={styles.dateInput}
        ref={dateInputRef} 
      >
        {startDate && endDate
          ? <div><span>{formatDisplayDate(startDate)}</span>-<span>{formatDisplayDate(endDate)}</span></div>
          : 'Selecione uma data'}
      </div>

      {isCalendarOpen && (
        <div className={styles.calendar} ref={calendarRef}>
          <div className={styles.header}>
            {viewMode === 'days' && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <span onClick={() => handleMonthYearClick('months')} className={styles.month}>
                    {new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })}
                  </span>
                  de
                  <span onClick={() => handleMonthYearClick('years')} className={styles.year}>
                    {currentYear}
                  </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                  <div style={{ position: 'relative', display: 'flex' }}>
                    <LuCalendarSearch onClick={() => setIsPeriodSelectOpen(!isPeriodSelectOpen)} className={styles.navButton} size={18} />
                    {isPeriodSelectOpen && (
                      <div
                        className={styles.periodSelect}
                        ref={periodSelectRef} // Adicione o ref aqui
                        onClick={(e) => e.stopPropagation()} // Impede que cliques dentro fechem o seletor
                      >
                        {/* Campo de input para número de dias personalizados */}
                        <input
                          type="text" // Mantenha o tipo como "text"
                          value={customDays}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Filtra para permitir apenas números e limita a 3 caracteres
                            if (/^\d*$/.test(value) && value.length <= 3) { // Regex para permitir apenas dígitos
                              setCustomDays(value);
                              if (value) {
                                handlePeriodSelect(Number(value)); // Atualiza o calendário conforme o usuário digita
                              }
                            }
                          }}
                          placeholder="Digite aqui ou..."
                          className={styles.customDaysInput}
                          onClick={(e) => e.stopPropagation()} // Impede que cliques no input fechem o seletor
                        />
                        {periodOptions.map((option) => (
                          <div
                            key={option.value}
                            className={styles.optionItem}
                            onClick={() => {
                              handlePeriodSelect(option.value); // Chama o método e fecha o seletor
                              setIsPeriodSelectOpen(false); // Fecha o seletor de períodos ao clicar nas opções presetadas
                            }}
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <TiArrowSortedUp onClick={handlePreviousMonth} className={styles.navButton} size={18}/>
                    <TiArrowSortedDown onClick={handleNextMonth} className={styles.navButton} size={18}/>
                  </div>
                </div>
              </>
            )}
            {viewMode === 'months' && <span>Selecione o mês</span>}
            {viewMode === 'years' && <span>Selecione o ano</span>}
          </div>

          <div className={styles.content}>
            {viewMode === 'days' && (
              <>
                <div className={styles.weekDays}>
                  {['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'].map((day, index) => (
                    <div key={index} className={styles.weekDay}>
                      {day}
                    </div>
                  ))}
                </div>
                <div className={styles.daysContainer}>{renderCalendarDays()}</div>
              </>
            )}
            {viewMode === 'months' && renderMonthPicker()}
            {viewMode === 'years' && renderYearPicker()}
          </div>

          {viewMode === 'days' && (
            <button onClick={handleApplyClick} className={styles.applyButton}>
              Aplicar
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
