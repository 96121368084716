import React, { useState, useEffect, useRef } from 'react';
import styles from './flow.module.css';

import Abmex from '../../../images/landing/logo/abmex.svg';
import Iugu from '../../../images/landing/logo/iugu.svg';
import Konduto from '../../../images/landing/logo/konduto.svg';
import Hubpix from '../../../images/landing/logo/Hubpix.svg';
import Santander from '../../../images/landing/logo/Santander.svg';
import Rede from '../../../images/landing/logo/rede.svg';
import Pagbank from '../../../images/landing/logo/pagbank.svg';
import Getnet from '../../../images/landing/logo/getnet.svg';
import Cielo from '../../../images/landing/logo/cielo.svg';
import Assas from '../../../images/landing/logo/assas.svg';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const logos = [Abmex, Iugu, Cielo, Rede, Getnet, Assas, Pagbank];

const App = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animation, setAnimation] = useState('');
  const [isManual, setIsManual] = useState(false);
  const timeoutRef = useRef(null);

  const handlePrevious = () => {
    setAnimation(styles.LogoExit);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : logos.length - 1));
      setAnimation(styles.LogoEnter);
    }, 300);
    resetAutoSlide();
  };

  const handleNext = () => {
    setAnimation(styles.LogoExit);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex < logos.length - 1 ? prevIndex + 1 : 0));
      setAnimation(styles.LogoEnter);
    }, 300);
    resetAutoSlide();
  };

  const resetAutoSlide = () => {
    setIsManual(true);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setIsManual(false);
    }, 5000);
  };

  useEffect(() => {
    if (!isManual) {
      const interval = setInterval(() => {
        handleNext();
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isManual]);

  return (
    <div className={styles.Container}>
      <button onClick={handlePrevious}><IoIosArrowBack size={24} /></button>
      <div className={styles.LogosContent}>
        <img
          src={logos[currentIndex]}
          alt={`Logo ${currentIndex + 1}`}
          className={`${styles.Logo} ${animation}`}
        />
      </div>
      <button onClick={handleNext}><IoIosArrowForward size={24} /></button>
    </div>
  );
};

export default App;