import React, { useState, useEffect, useCallback } from 'react';
import customAxios from '../../../../../utils/customaxios';
import Popup from '../../../components/popup/popup';
import Table from '../../../components/table/table';
import Input from '../../../components/input/input';  // Importa o componente de Input
import Select from '../../../components/select/select';  // Importa o componente de Select
import styles from './list.module.css';
import { useTheme } from '../../../components/themecontext/ThemeContext';

const API_URL = 'https://client.rapidchargeback.com/api/app/company';

const App = () => {
  const [companies, setCompanies] = useState([]); // Estado para armazenar a lista de empresas
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar a visibilidade do popup
  const { theme } = useTheme();
  const [editingCompany, setEditingCompany] = useState({
    name: '',
    company_name: '',
    cnpj: '',
    email: '',
    clientid: '',
    clientkey: '',
    Id: '',
    number: '',
    created_at: '',
    merchant_visa: '',
    urlapivisa: '',
    currency_visa: '',
    tier_1_price: '',
    tier_2_price: '',
    tier_3_price: '',
    last_alert_visa_id: '',
    last_alert_visa_at: '',
    merchant_mastercard: '',
    urlapimastercard: '',
    currency_mastercard: '',
    mastercard_price: '',
    last_alert_mastercard_id: '',
    last_alert_mastercard_at: '',
  }); // Estado para armazenar a empresa que está sendo editada
  const [selectedSubgroup, setSelectedSubgroup] = useState('Alerta de Chargeback'); // Estado para controlar o subgrupo selecionado
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const [totalPages, setTotalPages] = useState(1); // Estado para o número total de páginas
  const [itemsPerPage, setItemsPerPage] = useState(20); // Estado para o número de itens por página
  const [totalItems, setTotalItems] = useState(0); // Estado para o número total de itens
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento

  useEffect(() => {
    fetchCompanies(); // Buscar empresas ao carregar o componente
  }, [currentPage, itemsPerPage]); // Dependências de paginação

  // Função para buscar empresas da API
  const fetchCompanies = useCallback(async () => {
    setIsLoading(true); // Inicia o estado de carregamento
    try {
      const response = await customAxios.get(`${API_URL}/list`, {
        params: { page: currentPage, limit: itemsPerPage }, // Parâmetros de paginação
      });
      if (response.data?.data) {
        setCompanies(response.data.data); // Definir lista de empresas no estado
        setTotalItems(response.data.totalItems); // Definir o número total de itens
        setTotalPages(response.data.totalPages); // Definir o número total de páginas
      } else {
        setCompanies([]);
        setTotalItems(0);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
      setCompanies([]);
      setTotalItems(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false); // Finaliza o estado de carregamento
    }
  }, [currentPage, itemsPerPage]);

  // Função para abrir o popup de edição para uma empresa específica
  const handleEditClick = (company) => {
    setEditingCompany(company);
    setIsPopupOpen(true);
  };

  // Função para deletar uma empresa
  const handleDeleteCompany = async (id) => {
    if (!window.confirm('Tem certeza que deseja deletar esta empresa?')) return; // Confirmação antes de deletar
    try {
      await customAxios.delete(`${API_URL}/delete/${id}`);
      alert('Empresa deletada com sucesso!');
      setIsPopupOpen(false); // Fechar o popup após deletar a empresa
      fetchCompanies(); // Atualiza a lista de empresas
    } catch (error) {
      console.error('Erro ao deletar empresa:', error);
      alert('Erro ao deletar empresa.');
    }
  };

  // Função para enviar a edição de uma empresa
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await customAxios.put(`${API_URL}/update/${editingCompany.Id}`, editingCompany);
      alert('Empresa atualizada com sucesso!');
      setIsPopupOpen(false);
      fetchCompanies(); // Atualiza a lista de empresas
    } catch (error) {
      console.error('Erro ao atualizar empresa:', error);
      alert('Erro ao atualizar empresa.');
    }
  };

  // Função para lidar com a mudança de entrada no formulário de edição
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingCompany((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Função para lidar com a mudança de página
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Função para lidar com a mudança de itens por página
  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reinicia para a primeira página ao mudar o número de itens por página
  };

  // Definindo colunas básicas da tabela
  const columns = [ 
    {
      Header: 'RAZÃO SOCIAL',
      accessor: 'company_name',
      Cell: ({ value }) => value.toUpperCase(),
    },
    { Header: 'CNPJ', accessor: 'cnpj' }, // Adicione mais colunas conforme necessário
  ];

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Verifica se a string de data é vazia ou indefinida
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return dateString; // Retorna a string original se não puder ser convertida para uma data válida
    }
    return date.toLocaleString(); // Converte para string de data/hora no formato local
  };

  return (
    <div className={`${theme === 'dark' ? styles.dark : styles.light}`}>
      {/* Tabela de Empresas */}
      <Table
        columns={columns}
        data={companies}
        renderActions={(company) => (
          <>
            <button className={styles.button_popup} onClick={() => handleEditClick(company)}>Ver detalhes</button>
          </>
        )}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        noDataMessage="Nenhuma empresa disponível para exibição."
        isLoading={isLoading}
        loadingMessage="Buscando empresas, por favor, aguarde..."
      />

      {/* Popup de Edição de Empresa */}
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={() => setIsPopupOpen(false)}
          maxWidth="600px"
          title="Detalhes da empresa"
          firstChild={
            <div className={styles.popup}>
              <form id="editForm" onSubmit={handleEditSubmit}>
                {/* Grupo de Informações Principais */}
                <div className={styles.grid}>
                  <div className={styles.grid_item}>
                    <h4>Nome</h4>
                    <Input
                      name="name"
                      value={editingCompany.name}
                      onChange={handleInputChange}
                      placeholder="Nome da empresa"
                      maxWidth="100%"
                      borderRadius="10px"
                    />
                  </div>
                  <div className={styles.grid_item}>
                    <h4>Razão social</h4>
                    <Input
                      name="company_name"
                      value={editingCompany.company_name}
                      onChange={handleInputChange}
                      placeholder="Razão social da empresa"
                      maxWidth="100%"
                      borderRadius="10px"
                    />
                  </div>
                  <div className={styles.grid_item}>
                    <h4>CNPJ</h4>
                    <Input
                      name="cnpj"
                      value={editingCompany.cnpj}
                      onChange={handleInputChange}
                      placeholder="CNPJ da empresa"
                      maxWidth="100%"
                      borderRadius="10px"
                    />
                  </div>
                  <div className={styles.grid_item}>
                    <h4>Email</h4>
                    <Input
                      name="email"
                      value={editingCompany.email}
                      onChange={handleInputChange}
                      placeholder="Email de contato da empresa"
                      maxWidth="100%"
                      borderRadius="10px"
                    />
                  </div>
                </div>
                <div className={styles.grid} style={{ marginTop: '30px' }}>
                  <div className={styles.grid_item}>
                    <h4>ClientId</h4>
                    <p>{editingCompany.clientid || 'Desconhecido'}</p>
                  </div>
                  <div className={styles.grid_item}>
                    <h4>ClientKey</h4>
                    <p>{editingCompany.clientkey || 'Desconhecido'}</p>
                  </div>
                  <div className={styles.grid_item}>
                    <h4>Id</h4>
                    <p>{editingCompany.Id || 'Desconhecido'}</p>
                  </div>
                  <div className={styles.grid_item}>
                    <h4>Numero identificador</h4>
                    <p>{editingCompany.number || 'Desconhecido'}</p>
                  </div>
                  <div className={styles.grid_item}>
                    <h4>Criada em</h4>
                    <p>{editingCompany.created_at || 'Desconhecido'}</p>
                  </div>
                </div>

                {/* Controle para Selecionar o Subgrupo a Ser Exibido */}
                <div className={styles.grid_item} style={{ marginTop: '60px', marginBottom: '20px' }}>
                  <h4>Produto</h4>
                  <Select
                    name="subgroupSelect"
                    value={selectedSubgroup}
                    onChange={(e) => setSelectedSubgroup(e.target.value)}
                    options={[
                      { value: 'Alerta de Chargeback', label: 'Alerta de Chargeback' },
                    ]}
                    borderRadius="10px"
                  />
                </div>

                {/* Subgrupo de Informações (Item A) */}
                {selectedSubgroup === 'Alerta de Chargeback' && (
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div className={styles.subgroup}>
                      <h4 style={{ marginBottom: '20px' }}>Visa</h4>
                      <div className={styles.grid2}>
                        <div className={styles.grid_item}>
                          <h4>Descriptor</h4>
                          <Input
                            name="merchant_visa"
                            value={editingCompany.merchant_visa}
                            onChange={handleInputChange}
                            placeholder="DescriptorName"
                            maxWidth="100%"
                            borderRadius="10px"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Endpoint de envio</h4>
                          <Input
                            name="urlapivisa"
                            value={editingCompany.urlapivisa}
                            onChange={handleInputChange}
                            placeholder="URL do Endpoint"
                            maxWidth="100%"
                            borderRadius="10px"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Moeda de câmbio</h4>
                          <Select
                            name="currency_visa"
                            value={editingCompany.currency_visa}
                            onChange={handleInputChange}
                            options={[
                              { value: 'Real', label: 'Real' },
                              { value: 'Dolar', label: 'Dolar' },
                            ]}
                            borderRadius="10px"
                            maxWidth="100%"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Preço do Tier 1</h4>
                          <Input
                            name="tier_1_price"
                            value={editingCompany.tier_1_price}
                            onChange={handleInputChange}
                            placeholder="Exemplo: 5.80"
                            maxWidth="100%"
                            borderRadius="10px"
                            onlyNumbersAndDots={true}
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Preço do Tier 2</h4>
                          <Input
                            name="tier_2_price"
                            value={editingCompany.tier_2_price}
                            onChange={handleInputChange}
                            placeholder="Exemplo: 20"
                            maxWidth="100%"
                            borderRadius="10px"
                            onlyNumbersAndDots={true}
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Preço do Tier 3</h4>
                          <Input
                            name="tier_3_price"
                            value={editingCompany.tier_3_price}
                            onChange={handleInputChange}
                            placeholder="Exemplo: 34.50"
                            maxWidth="100%"
                            borderRadius="10px"
                            onlyNumbersAndDots={true}
                          />
                        </div>
                        <div className={styles.grid_item} style={{ marginTop: '10px' }}>
                          <h4>Último alerta recebido</h4>
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <p><small>Id: {editingCompany.last_alert_visa_id || 'Desconhecido'}</small></p>
                            <p><small>Data: {formatDate(editingCompany.last_alert_visa_at) || 'Desconhecido'}</small></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.subgroup}>
                      <h4 style={{ marginBottom: '20px' }}>Mastercard</h4>
                      <div className={styles.grid2}>
                        <div className={styles.grid_item}>
                          <h4>Descriptor</h4>
                          <Input
                            name="merchant_mastercard"
                            value={editingCompany.merchant_mastercard}
                            onChange={handleInputChange}
                            placeholder="Merchant"
                            maxWidth="100%"
                            borderRadius="10px"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Endpoint de envio</h4>
                          <Input
                            name="urlapimastercard"
                            value={editingCompany.urlapimastercard}
                            onChange={handleInputChange}
                            placeholder="URL do Endpoint"
                            maxWidth="100%"
                            borderRadius="10px"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Moeda de câmbio</h4>
                          <Select
                            name="currency_mastercard"
                            value={editingCompany.currency_mastercard}
                            onChange={handleInputChange}
                            options={[
                              { value: 'Real', label: 'Real' },
                              { value: 'Dolar', label: 'Dolar' },
                            ]}
                            borderRadius="10px"
                            maxWidth="100%"
                          />
                        </div>
                        <div className={styles.grid_item}>
                          <h4>Preço</h4>
                          <Input
                            name="mastercard_price"
                            value={editingCompany.mastercard_price}
                            onChange={handleInputChange}
                            placeholder="Exemplo: 40"
                            maxWidth="100%"
                            borderRadius="10px"
                            onlyNumbersAndDots={true}
                          />
                        </div>
                        <div className={styles.grid_item} style={{ marginTop: '10px' }}>
                          <h4>Último alerta recebido</h4>
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <p><small>Id: {editingCompany.last_alert_mastercard_id || 'Desconhecido'}</small></p>
                            <p><small>Data: {formatDate(editingCompany.last_alert_mastercard_at) || 'Desconhecido'}</small></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          }
          secondChild={
            <div className={styles.popup_footer}>
              <button onClick={() => handleDeleteCompany(editingCompany.Id)}>Deletar</button>
              <button type="submit" form="editForm">Salvar</button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default App;
