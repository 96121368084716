import React, { useState, useEffect } from 'react';
import customAxios from '../../../../../utils/customaxios';
import Popup from '../../../components/popup/popup';
import styles from './list.module.css';
import Table from '../../../components/table/table'; // Importando o componente atualizado

const formatDate = (dateString) => {
  if (!dateString) return ''; // Verifica se a string de data é vazia ou indefinida
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return dateString; // Retorna a string original se não puder ser convertida para uma data válida
  }
  return date.toLocaleString(); // Converte para string de data/hora no formato local
};


const App = () => {
  const [accounts, setAccounts] = useState([]); // Inicializa como array vazio
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [editFormData, setEditFormData] = useState({
    name: '',
    email: '',
    role: '',
    telephone: '',
    company_1: '',
    company_2: '',
    company_3: '',
    company_4: '',
    company_5: '',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchAccounts();
  }, [currentPage, itemsPerPage]);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await customAxios.get(`https://client.rapidchargeback.com/api/app/account/list`, {
        params: {
          page: currentPage,
          limit: itemsPerPage,
        },
      });

      if (response.data && Array.isArray(response.data.data)) {
        const formattedAccounts = response.data.data.map((account) => ({
          ...account,
          created_at: formatDate(account.created_at),
          update_at: formatDate(account.update_at),
        }));
        setAccounts(formattedAccounts);
        setTotalItems(response.data.total); // Supondo que a API retorne o total de itens
        setTotalPages(response.data.totalPages); // Supondo que a API retorne o total de páginas
      } else {
        setAccounts([]);
      }
    } catch (error) {
      setAccounts([]);
    }
    setIsLoading(false);
  };

  const handleEditClick = (account) => {
    setEditingAccount(account.Id);
    setEditFormData({
      name: account.name || '',
      email: account.email || '',
      role: account.role || '',
      telephone: account.telephone || '',
      referral_code: account.referral_code || '',
      referred_by: account.referred_by || '',
      created_at: account.created_at || '',
      update_at: account.update_at || '',
      company_1: account.company_1 || '',
      company_2: account.company_2 || '',
      company_3: account.company_3 || '',
      company_4: account.company_4 || '',
      company_5: account.company_5 || '',
    });
    setIsPopupOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      await customAxios.put(`https://client.rapidchargeback.com/api/app/account/update/${editingAccount}`, editFormData);
      alert('Conta atualizada com sucesso!');
      setIsPopupOpen(false);
      fetchAccounts(); // Atualiza a lista de contas
    } catch (error) {
      alert('Erro ao atualizar conta.');
    }
  };

  const handleDeleteAccount = async (id) => {
    if (window.confirm('Tem certeza que deseja deletar essa conta?')) {
      try {
        await customAxios.delete(`https://client.rapidchargeback.com/api/app/account/delete/${id}`);
        alert('Conta deletada com sucesso!');
        fetchAccounts(); // Atualiza a lista de contas
      } catch (error) {
        alert('Erro ao deletar conta.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleItemsPerPageChange = (items) => {
    setItemsPerPage(items);
  };

  const columns = [
    { Header: 'Nome', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Última Atualização', accessor: 'update_at' },
  ];

  const fieldLabels = {
    name: 'Nome',
    email: 'E-mail',
    telephone: 'Telefone',
    referral_code: 'Código de indicação',
    referred_by: 'Indicado por',
    created_at: 'Data de criação',
    update_at: 'Ultimo login em',
    company_1: 'Empresa 1',
    company_2: 'Empresa 2',
    company_3: 'Empresa 3',
    company_4: 'Empresa 4',
    company_5: 'Empresa 5',
  };

  return (
    <div>
      <Table 
        columns={columns}
        data={accounts}
        renderActions={(account) => (
          <>
            <button onClick={() => handleEditClick(account)}>Editar</button>
            <button onClick={() => handleDeleteAccount(account.Id)}>Deletar</button> {/* Botão de deletar na tabela */}
          </>
        )}
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        noDataMessage="Nenhuma conta encontrada."
        isLoading={isLoading}
        loadingMessage="Carregando contas..."
      />

      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        maxWidth="600px"
        title="Detalhes da conta"
        firstChild={
          <div className={styles.popup}>
            <form id="editForm" onSubmit={handleEditSubmit}>
              {['name', 'email', 'telephone', 'referral_code', 'referred_by', 'created_at', 'update_at', 'company_1', 'company_2', 'company_3', 'company_4', 'company_5'].map((key) => (
                <div key={key} className={styles.formGroup}>
                  <label>{fieldLabels[key] || key}</label>
                  <input
                    type="text"
                    name={key}
                    value={editFormData[key]}
                    onChange={handleInputChange}
                    disabled={['referral_code', 'referred_by', 'created_at', 'update_at'].includes(key)}
                  />
                </div>
              ))}
              <div>
                <label>Role</label>
                <select name="role" value={editFormData.role} onChange={handleInputChange}>
                  <option value="member">Member</option>
                  <option value="admin">Admin</option>
                  <option value="financial">Financeiro</option>
                  <option value="developer">Developer</option>
                </select>
              </div>
            </form>
          </div>
        }
        secondChild={
          <div className={styles.popup_footer}>
            <button type="submit" form="editForm">Salvar</button>
            <button onClick={() => handleDeleteAccount(editingAccount)}>Deletar</button> {/* Botão de deletar no popup */}
          </div>
        }
      />
    </div>
  );
};

export default App;
