import React from 'react';
import styles from './notice.module.css';
import { useTheme } from '../themecontext/ThemeContext';

const App = ({ title, description }) => {
  const { theme } = useTheme();
  return (
    <div className={theme === 'dark' ? styles.dark : styles.light}>
      <div className={styles.container}>
        <p><span>{title}</span> {description}</p>
      </div>
    </div>
  );
};

export default App;
