import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from '../../components.module.css';
import axios from 'axios';
import Table from '../../../../../components/table/table';
import DatePicker from '../../../../../components/datapicker/datapicker';
import Popup from '../../../../../components/popup/popup';
import FilterComponent from '../../../../../components/filtercomponent/filtercomponent';
import InfoPopup from '../../../../../components/infopopup/infopopup'
import { useTheme } from '../../../../../components/themecontext/ThemeContext';
import { IoSearch } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline, IoMdTime } from 'react-icons/io';
import { LuDownload } from "react-icons/lu";
import DownloadInvoiceButton from '../../pdf/download';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import '../../../../../../tooltip/tooltipstyles.css';

// Hook personalizado para buscar dados
const useFetchMetrics = (startDate, endDate, setOriginalData) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');

        // Chamar as duas APIs ao mesmo tempo
        const [allInvoicesResponse, inProgressInvoicesResponse] = await Promise.all([
          axios.get('https://client.rapidchargeback.com/api/product/chargebackalert/billing/invoice/list', {
            params: { startDate, endDate },
            headers: { Authorization: token },
          }),
          axios.get('https://client.rapidchargeback.com/api/product/chargebackalert/billing/invoice/list/inprogress', {
            headers: { Authorization: token },
          }),
        ]);

        // Extrair dados de cada resposta
        const allInvoices = Array.isArray(allInvoicesResponse.data) ? allInvoicesResponse.data : allInvoicesResponse.data.data;
        const inProgressInvoices = Array.isArray(inProgressInvoicesResponse.data.data) ? inProgressInvoicesResponse.data.data : [];

        // Combinar os dois arrays de faturas
        let combinedData = [...allInvoices, ...inProgressInvoices];

        // Classificar para garantir que as faturas "em andamento" fiquem no topo
        combinedData = combinedData.sort((a, b) => {
          if (a.invoiceStatus === 'progress' && b.invoiceStatus !== 'progress') return -1;
          if (a.invoiceStatus !== 'progress' && b.invoiceStatus === 'progress') return 1;
          return 0;
        });

        // Atualizar o estado com os dados combinados
        setOriginalData(combinedData);
      } catch (err) {
        setError('Erro ao buscar métricas');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, setOriginalData]);

  return { loading, error };
};

// Função para obter opções de filtro do token no localStorage
const getFilterOptionsFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return [{ label: 'Todas empresas', values: ['Todas empresas'] }];

  try {
    const base64Payload = token.split('.')[1];
    const jsonPayload = JSON.parse(atob(base64Payload));

    const filterValues = Object.keys(jsonPayload)
      .filter((key) => key.startsWith('merchant_visa_'))
      .map((key) => jsonPayload[key]);

    return [
      { label: 'Todas empresas', values: ['Todas empresas'] },
      ...filterValues.map((value) => ({ label: value, values: [value] }))
    ];
  } catch (error) {
    /*console.error('Erro ao decodificar o token:', error);*/
    return [{ label: 'Todas empresas', values: ['Todas empresas'] }];
  }
};

const App = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { theme } = useTheme();
  const { loading: isLoading, error } = useFetchMetrics(startDate, endDate, setOriginalData);

  // Configurações de filtros
  const filterConfigs = [
    {
      field: 'companyName',
      type: 'select',
      options: getFilterOptionsFromToken(),
      defaultValue: 'Todas empresas',
      label: 'Empresa'
    },
    {
      field: 'invoiceStatus',
      type: 'select',
      options: [
        { label: 'Todos status', values: ['Todos status'] },
        { label: 'Concluído', values: ['completed'] },
        { label: 'Em andamento', values: ['progress', null, ''] },
      ],
      defaultValue: 'Todos status',
      label: 'Status da fatura'
    },
    {
      field: 'paymentStatus',
      type: 'select',
      options: [
        { label: 'Todos status', values: ['Todos status'] },
        { label: 'Pago', values: ['paid'] },
        { label: 'Pendente', values: ['pending', null, ''] },
      ],
      defaultValue: 'Todos status',
      label: 'Status do pagamento'
    },
    {
      field: 'invoiceId',
      defaultValue: '',
      placeholder: 'Digite o id da fatura',
      type: 'input',
      typeInput: 'text',
      icon: IoSearch,
      iconColor: 'gray',
      borderRadius: "10px",
      label: 'Id'
    }
  ];

  const [filters, setFilters] = useState(
    filterConfigs.map((config) => ({ field: config.field, value: config.defaultValue }))
  );

  const applyFilters = useCallback(
    (data, filters) => {
      return filters.reduce((acc, filter) => {
        const { field, value } = filter;
        const filterConfig = filterConfigs.find((config) => config.field === field);
  
        if (!filterConfig || value === filterConfig.defaultValue) return acc;
  
        if (filterConfig.type === 'input') {
          if (field) {
            return value ? acc.filter(item => item[field] && item[field].toString().includes(value)) : acc;
          }
        } else if (filterConfig.type === 'select') {
          const selectedOption = filterConfig.options.find(option => option.label === value);
          if (selectedOption) {
            return acc.filter(item => selectedOption.values.includes(item[field]));
          }
        }
  
        return acc;
      }, data);
    },
    []
  );

  const filteredData = useMemo(() => applyFilters(originalData, filters), [originalData, filters, applyFilters]);

  const mappedData = filteredData.map(item => ({
    ...item,
    invoiceStatus: item.invoiceStatus === 'progress' ? 'Em andamento' : 
                   item.invoiceStatus === 'completed' ? 'Concluído' : item.invoiceStatus,
    paymentStatus: item.paymentStatus === 'paid' ? 'Pago' : 
                   item.paymentStatus === 'pending' ? 'Pendente' : item.paymentStatus,
  }));

  // Calcular totalPages após filteredData ser definido
  const totalPages = useMemo(() => Math.ceil(filteredData.length / itemsPerPage), [filteredData, itemsPerPage]);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reinicia para a primeira página
  };

  const handleFilterChange = useCallback((field, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.field === field ? { ...filter, value } : filter
      )
    );
  }, []);

  const handleDateChange = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const handleOpenPopup = (item) => {
    setSelectedItem(item);
    setIsPopupOpen(true);
  };  

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const [year, month, day] = dateStr.split('-');
    return `${day}/${month}/${year}`; // Remonta a data no formato 'DD/MM/YYYY'
  };

  const paginatedData = useMemo(
    () => filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [filteredData, currentPage, itemsPerPage]
  );

  const handleOpenFilterPopup = () => setIsFilterPopupOpen(true);
  const handleCloseFilterPopup = () => setIsFilterPopupOpen(false);

  const formatCurrency = (value) => {
    if (!value) return 'R$ 0,00';  // Caso o valor seja nulo ou indefinido
    return Number(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.filter_container}>
        <DatePicker onDateChange={handleDateChange} />
        <button onClick={handleOpenFilterPopup} className={styles.button_popup}>+ Filtros</button>
      
        {/* Popup para Filtros */}
        <Popup
          isOpen={isFilterPopupOpen}
          onClose={handleCloseFilterPopup}
          title="Filtros"
          firstChild={
            <div className={styles.filter_container_popup}>
              {filterConfigs.map((config) => (
                <FilterComponent
                  key={config.field}
                  config={config}
                  value={filters.find((filter) => filter.field === config.field)?.value}
                  onChange={handleFilterChange}
                />
              ))}
            </div>
          }
          secondChild={null}
          maxWidth="600px"
        />
      </div>
      <div className={styles.info_container}>
        <InfoPopup
          data={mappedData}
          fieldName="invoiceStatus"
          popupTitle="Status da fatura"
          iconMapping={{
            'Concluído': IoMdCheckmarkCircleOutline,
            'Em andamento': IoMdTime,
          }}
          maxWidth="400px"
        />
        <InfoPopup
          data={mappedData}
          fieldName="paymentStatus"
          popupTitle="Status do pagamento"
          iconMapping={{
            'Pago': IoMdCheckmarkCircleOutline,
            'Pendente': IoMdTime,
          }}
          maxWidth="400px"
        />
      </div>
      <Table
        columns={[
          {
            Header: 'ID',
            accessor: 'invoiceId',
            Cell: ({ value }) => value || 'Desconhecido',
          },
          {
            Header: 'PERÍODO',
            accessor: (row) => ({ startDate: row.startDate, endDate: row.endDate }),
            Cell: ({ value }) => {
              if (!value || !value.startDate || !value.endDate) return 'Desconhecido';
              const formattedStartDate = value.startDate ? formatDate(value.startDate) : 'Desconhecido';
              const formattedEndDate = value.endDate ? formatDate(value.endDate) : 'Desconhecido';
              return `${formattedStartDate} - ${formattedEndDate}`;
            },
          },
          {
            Header: 'EMPRESA',
            accessor: 'companyName',
            Cell: ({ value }) => {
              if (!value) return 'Desconhecido'; // Retorna "Desconhecido" se o valor for nulo ou indefinido
          
              // Usa expressão regular para capturar a parte do nome que queremos
              const match = value.match(/(?:.*[_-])?([a-zA-Z]+)$/i);
              
              // Se o regex encontrar um match, retorna o grupo capturado; caso contrário, retorna o valor original
              return match ? match[1].toUpperCase() : value;
            },
          },
          {
            Header: 'STATUS DA FATURA',
            accessor: 'invoiceStatus',
            Cell: ({ value }) => {
              let statusClass = '';
      
              if (value === 'progress') statusClass = styles.status_inprogress;
              else if (value === 'completed') statusClass = styles.status_finalized;
      
              return (
                <span className={statusClass}>
                  {value === 'progress' ? 'Em andamento' : value === 'completed' ? 'Concluído' : value || 'Em andamento'}
                </span>
              );
            },
          },
          {
            Header: 'STATUS DO PAGAMENTO',
            accessor: 'paymentStatus',
            Cell: ({ value }) => {
              let paymentClass = '';
      
              if (value === 'pending') paymentClass = styles.status_notfinalized;
              else if (value === 'paid') paymentClass = styles.status_finalized;
      
              return (
                <span className={paymentClass}>
                  {value === 'pending' ? 'Pendente' : value === 'paid' ? 'Pago' : value || 'Pendente'}
                </span>
              );
            },
          },
        ]}
        data={paginatedData}
        renderActions={(row) => (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <button className={styles.button_popup} onClick={() => handleOpenPopup(row)}>
              Ver detalhes
            </button>
            <Tippy content="Baixar fatura" className="customtooltip">
              <div>
                <DownloadInvoiceButton invoiceData={row} text={<LuDownload className={styles.button_download} size={20}/>}/>
              </div>
            </Tippy>
          </div>
        )}
        currentPage={currentPage}
        totalPages={totalPages} // Usa o totalPages calculado dinamicamente
        onPageChange={setCurrentPage}
        totalItems={filteredData.length}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        noDataMessage="Nenhuma fatura disponível para exibição."
        isLoading={isLoading}
        loadingMessage="Buscando faturas, por favor, aguarde..."
      />
      <Popup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title="Detalhes da fatura"
        firstChild={
          selectedItem && (
            <div className={`${theme === 'dark' ? styles.dark : styles.light}`}>
              <div className={styles.invoice_details_popup}>
                {/* Grupo Informacoes */}
                <div className={styles.group_info}>
                  <div className={styles.subgroup_info}>
                    <div>
                      <h5>Id</h5>
                      <p>{selectedItem.invoiceId || 'Desconhecido'}</p>
                    </div>
                    <div>
                      <h5>Período</h5>
                      <p>{formatDate(selectedItem.startDate || 'Desconhecido')} - {formatDate(selectedItem.endDate || 'Desconhecido')}</p>
                    </div>
                    <div>
                      <h5>Empresa</h5>
                      <p>{selectedItem.companyName || 'Desconhecido'}</p>
                    </div>
                    <div>
                      <h5>Razão social</h5>
                      <p>{selectedItem.company_Name || 'Desconhecido'}</p>
                    </div>
                    <div>
                      <h5>CNPJ</h5>
                      <p>{selectedItem.companyCnpj || 'Desconhecido'}</p>
                    </div>
                    <div>
                      <h5>Email</h5>
                      <p>{selectedItem.companyEmail || 'Desconhecido'}</p>
                    </div>
                    <div>
                      <h5>Total de alertas</h5>
                      <p>{selectedItem.totalAlerts || 0}</p>
                    </div>
                    <div>
                      <h5>Valor total</h5>
                      <p>{formatCurrency(selectedItem.totalAmount)}</p>
                    </div>
                    <div>
                      <h5>Status da fatura</h5>
                      <p className={selectedItem.invoiceStatus === 'completed' ? styles.status_finalized : styles.status_inprogress}>
                        {selectedItem.invoiceStatus === 'completed' ? 'Concluído' : 'Em andamento'}
                      </p>
                    </div>
                    <div>
                      <h5>Status do pagamento</h5>
                      <p className={selectedItem.paymentStatus === 'pending' ? styles.status_notfinalized : styles.status_finalized}>
                        {selectedItem.paymentStatus === 'pending' ? 'Pendente' : 'Pago'}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Grupo Visa */}
                <div className={styles.group_details}>
                  <h4>Visa</h4>
                  <div className={styles.subgroup_container_details}>
                    {/* Subgrupo de Alertas */}
                    <div className={styles.subgroup_details}>
                      <h5>Quantidade</h5>
                      <p>Total: {selectedItem.totalVisaAlerts || 0}</p>
                      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '10px' }}>
                        <p>Tier 1: {selectedItem.visaTier1Alerts || 0}</p>
                        <p>Tier 2: {selectedItem.visaTier2Alerts || 0}</p>
                        <p>Tier 3: {selectedItem.visaTier3Alerts || 0}</p>
                      </div>
                    </div>
                    {/* Subgrupo de Valores */}
                    <div className={styles.subgroup_details}>
                      <h5>Valor</h5>
                      <p>Total: {formatCurrency(selectedItem.totalVisaAmount)}</p>
                      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', marginTop: '10px' }}>
                        <p>Tier 1: {formatCurrency(selectedItem.visaTier1Amount)}</p>
                        <p>Tier 2: {formatCurrency(selectedItem.visaTier2Amount)}</p>
                        <p>Tier 3: {formatCurrency(selectedItem.visaTier3Amount)}</p>
                      </div>
                    </div>
                    {/* Subgrupo de Precos */}
                    <div className={styles.subgroup_details}>
                      <h5>Preço</h5>
                      <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                        <p>Tier 1: {formatCurrency(selectedItem.visaTier1Price)}</p>
                        <p>Tier 2: {formatCurrency(selectedItem.visaTier2Price)}</p>
                        <p>Tier 3: {formatCurrency(selectedItem.visaTier3Price)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Grupo Mastercard */}
                <div className={styles.group_details}>
                  <h4>Mastercard</h4>
                  <div className={styles.subgroup_container_details}>
                    {/* Subgrupo de Alertas */}
                    <div className={styles.subgroup_details}>
                      <h5>Quantidade</h5>
                      <p>Total: {selectedItem.totalMastercardAlerts || 0}</p>
                    </div>
                    {/* Subgrupo de Valores */}
                    <div className={styles.subgroup_details}>
                      <h5>Valor</h5>
                      <p>Total: {formatCurrency(selectedItem.totalMastercardAmount)}</p>
                    </div>
                    {/* Subgrupo de Precos */}
                    <div className={styles.subgroup_details}>
                      <h5>Preço</h5>
                      <p>{formatCurrency(selectedItem.mastercardAlertPrice)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        secondChild={
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <button>{selectedItem && <DownloadInvoiceButton invoiceData={selectedItem} text='Baixar fatura'/>}</button>
          </div>
        }
        maxWidth="650px"
      />
    </div>
  );
};

export default App;