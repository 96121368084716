import React from 'react';
import styles from './belt.module.css';

import Zouti from '../../../images/landing/logo/zouti.svg';
import Abmex from '../../../images/landing/logo/abmex.svg';
import Iugu from '../../../images/landing/logo/iugu.svg';
import Konduto from '../../../images/landing/logo/konduto.svg';
import Hubpix from '../../../images/landing/logo/Hubpix.svg';
import Santander from '../../../images/landing/logo/Santander.svg';

const App = () => {
  return (
    <div className={styles.Esteira}>
      <div>
        <h3 className={styles.titleblack}>Empresas que confiam</h3>
      </div>
      <div className={styles.EsteiraContainer}>
        <div className={styles.EsteiraContent}>
            <div>
                <img src={Zouti} draggable="false" alt="Zouti" />
                <img src={Abmex} draggable="false" alt="Abmex" />
                <img src={Iugu} draggable="false" alt="Iugu" />
                <img src={Konduto} draggable="false" alt="Konduto" />
                <img src={Hubpix} draggable="false" alt="Hubpix" />
                <img src={Santander} draggable="false" alt="Santander" />
            </div>
            <div>
                <img src={Zouti} draggable="false" alt="Zouti" />
                <img src={Abmex} draggable="false" alt="Abmex" />
                <img src={Iugu} draggable="false" alt="Iugu" />
                <img src={Konduto} draggable="false" alt="Konduto" />
                <img src={Hubpix} draggable="false" alt="Hubpix" />
                <img src={Santander} draggable="false" alt="Santander" />
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;