import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from "./login.module.css";
import Logo from '../../../../../images/platform/Logo.svg';
import { TbArrowBackUp } from "react-icons/tb";

const App = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [erroMensagem, setErroMensagem] = useState('');
  const formRef = useRef(null);
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Verificação simples se os campos de e-mail e senha estão preenchidos
    if (!email || !password) {
      setErroMensagem('Preencha todos os campos.');
      return;
    }

    try {
      // Log de dados para verificar o que está sendo enviado
      /*console.log('Dados enviados:', { email, password });*/

      const response = await axios.post('https://client.rapidchargeback.com/api/app/auth/login', 
        { email, password },
        {
          headers: {
            'Authorization': 'FHYU8H4PRAPID2023CHARGEBACKFHYU8H4P'
          }
        }
      );

      // Armazenar o token no localStorage
      localStorage.setItem('token', response.data.token);

      if (isChecked) {
        localStorage.setItem('email', email);
      }

      // Navegar para o dashboard se a navegação estiver definida
      if (navigate) {
        navigate('/app/chargebackalert/dashboard');
      } else {
        /*console.error('Propriedade history não definida');*/
      }

    } catch (error) {
      /*console.error('Erro ao fazer login:', error);
      console.error('Detalhes do erro:', error.response || error.message || error);*/

      if (error.response && error.response.status === 401) {
        setErroMensagem('Credenciais inválidas. Verifique seu email e senha.');
      } else {
        setErroMensagem('Plataforma em manutenção, voltaremos em breve.');
      }

      // Limpar mensagem de erro após 5 segundos
      setTimeout(() => {
        setErroMensagem('');
      }, 5000);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const savedEmail = localStorage.getItem('email'); // Obtenha o e-mail salvo no localStorage
    if (token) {
      navigate('/app/chargebackalert/dashboard');
    }
    if (savedEmail) {
      setEmail(savedEmail); // Preencha o campo de e-mail com o e-mail salvo
    }
  }, [navigate]);  

  return (
    <div className={styles.Container}>
      <div className={styles.ContentLeft}>
        <div>
          <img draggable='false' src={Logo} alt="Logo" />
          <h1>Proteção <strong>avançada</strong> contra fraudes online.</h1>
          <div/>
        </div>
      </div>
      <div className={styles.ContentRight}>
        {erroMensagem && <p className={styles.errocontent}>{erroMensagem}</p>}
        <div title='Voltar' className={styles.Return} onClick={() => navigate('/')}>
          <TbArrowBackUp size={30}/>
        </div>
        <img draggable= 'false' src={Logo} alt="Logo" />
        <h1>Proteção <strong>avançada</strong> contra fraudes online.</h1>
        <div className={styles.FormModal}>
          <h4>Login</h4>
          <form id='formlogin' ref={formRef} onSubmit={handleLogin}>
            <div className={styles.Line}>
              <p>E-mail</p>
              <input 
                type="text" 
                required 
                placeholder="Digite seu e-mail aqui" 
                onChange={(e) => setEmail(e.target.value)} 
                value={email} 
              />
            </div>
            <div className={styles.Line}>
              <p>Senha</p>
              <input 
                type="password" 
                required 
                placeholder="Digite sua senha aqui" 
                onChange={(e) => setPassword(e.target.value)} 
                value={password} // Agora o estado da senha é controlado
              />
            </div>
          </form>
          <label className={styles.checkboxlabel}>
            <input
              type="checkbox"
              className={styles.checkboxinput}
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className={styles.checkboxcustom}></span>
            <p style={{ textAlign: 'start' }}>
              Lembrar meu e-mail
            </p>
          </label>
          <div>
            <button form="formlogin" type="submit" className={styles.buttonlogin}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
