import React, { useEffect, useState } from 'react';

const VisaAlertList = () => {
  const [alerts, setAlerts] = useState([]); // Estado para armazenar os alertas
  const [loading, setLoading] = useState(true); // Estado para controlar o loading
  const [error, setError] = useState(null); // Estado para controlar erros
  const [selectedDescriptor, setSelectedDescriptor] = useState(''); // Estado para o DescriptorName selecionado
  const [startDate, setStartDate] = useState(''); // Estado para a data de início
  const [endDate, setEndDate] = useState(''); // Estado para a data de fim

  useEffect(() => {
    const fetchAlerts = async () => {
      const token = localStorage.getItem('token'); // Obtém o token do localStorage

      if (!token) {
        setError('Token não encontrado no localStorage.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch('https://client.rapidchargeback.com/api/product/chargebackalert/alert/visa/all', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Adiciona o token ao cabeçalho da requisição
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erro ao buscar dados da API.');
        }

        const data = await response.json(); // Converte a resposta para JSON
        setAlerts(data.data); // Armazena os dados no estado
        setLoading(false); // Define o estado de loading para falso
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAlerts(); // Chama a função que faz a requisição
  }, []); // O array vazio como dependência significa que o useEffect só será chamado uma vez, quando o componente montar

  // Renderiza uma mensagem de loading enquanto os dados estão sendo carregados
  if (loading) {
    return <div>Carregando...</div>;
  }

  // Renderiza uma mensagem de erro se houver algum problema na requisição
  if (error) {
    return <div>Erro: {error}</div>;
  }

  // Manipulador para mudar o DescriptorName selecionado
  const handleDescriptorChange = (event) => {
    setSelectedDescriptor(event.target.value);
  };

  // Manipuladores para mudar as datas de início e fim
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  // Função para baixar o CSV com base nos filtros
  const handleDownload = () => {
    if (!selectedDescriptor || !startDate || !endDate) {
      alert('Por favor, selecione um DescriptorName e um período válido.');
      return;
    }

    const filteredAlerts = alerts.filter((alert) => {
      const arrivalDate = new Date(alert.ArrivalDate);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return (
        alert.DescriptorName === selectedDescriptor &&
        arrivalDate >= start &&
        arrivalDate <= end
      );
    });

    // Criação do conteúdo do CSV com as colunas "ID" e "ArrivalDate"
    const csvContent = 'ID,ArrivalDate\n' + filteredAlerts.map((alert) => `${alert.Id},${alert.ArrivalDate}`).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `alerts_${selectedDescriptor}_${startDate}_to_${endDate}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const descriptorNames = [...new Set(alerts.map(alert => alert.DescriptorName))];

  // Função para contar a quantidade de dados por mês, por DescriptorName e por dia
  const getMonthlyDescriptorDayCounts = () => {
    const monthlyCounts = {};

    alerts.forEach((alert) => {
      const date = new Date(alert.ArrivalDate);
      const month = date.getMonth() + 1; // Obtém o mês (de 0 a 11, por isso +1)
      const day = date.getDate(); // Obtém o dia do mês
      const descriptorName = alert.DescriptorName;

      if (!monthlyCounts[month]) {
        monthlyCounts[month] = { total: 0, descriptors: {} }; // Inicializa o objeto para o mês
      }

      // Incrementa o total de dados para o mês
      monthlyCounts[month].total++;

      // Inicializa o objeto para o DescriptorName se não existir
      if (!monthlyCounts[month].descriptors[descriptorName]) {
        monthlyCounts[month].descriptors[descriptorName] = { total: 0, days: {} };
      }

      // Incrementa o total de dados para o DescriptorName
      monthlyCounts[month].descriptors[descriptorName].total++;

      // Incrementa o contador para o dia específico
      if (monthlyCounts[month].descriptors[descriptorName].days[day]) {
        monthlyCounts[month].descriptors[descriptorName].days[day]++;
      } else {
        monthlyCounts[month].descriptors[descriptorName].days[day] = 1;
      }
    });

    return monthlyCounts;
  };

  const monthlyCounts = getMonthlyDescriptorDayCounts(); // Contagem dos alertas por mês, por DescriptorName e por dia

  // Função auxiliar para formatar o nome do mês
  const getMonthName = (monthNumber) => {
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return monthNames[monthNumber - 1]; // Ajusta o índice para o array
  };

  // Função para contar o total de dados por DescriptorName (independentemente do mês)
  const getTotalDescriptorCounts = () => {
    const descriptorCounts = {};

    alerts.forEach((alert) => {
      const descriptorName = alert.DescriptorName;
      if (descriptorCounts[descriptorName]) {
        descriptorCounts[descriptorName]++;
      } else {
        descriptorCounts[descriptorName] = 1;
      }
    });

    return descriptorCounts;
  };

  const totalDescriptorCounts = getTotalDescriptorCounts(); // Contagem total dos alertas por DescriptorName

  return (
    <div>
      <h1>Visa Alerts</h1>

      {/* Seletor de DescriptorName */}
      <div>
        <label htmlFor="descriptorSelect">Selecione um DescriptorName:</label>
        <select id="descriptorSelect" value={selectedDescriptor} onChange={handleDescriptorChange}>
          <option value="">--Selecione--</option>
          {descriptorNames.map((descriptor, index) => (
            <option key={index} value={descriptor}>
              {descriptor}
            </option>
          ))}
        </select>
      </div>

      {/* Inputs de data para escolher o período */}
      <div>
        <label htmlFor="startDate">Data de Início:</label>
        <input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} />
        <label htmlFor="endDate">Data de Fim:</label>
        <input type="date" id="endDate" value={endDate} onChange={handleEndDateChange} />
      </div>

      {/* Botão para baixar o CSV */}
      <button onClick={handleDownload}>Download CSV</button>

      {/* Div que exibe a quantidade de dados por mês, por DescriptorName e por dia */}
      <div>
        {Object.entries(monthlyCounts).map(([month, data]) => (
          <div key={month} style={{ marginBottom: '20px' }}>
            <h2>Mês {month.toString().padStart(2, '0')} - {getMonthName(month)}: total / {data.total} dados</h2>
            {Object.entries(data.descriptors).map(([descriptor, descriptorData]) => (
              <div key={descriptor} style={{ marginLeft: '20px' }}>
                <h3>{descriptor}: total / {descriptorData.total} dados</h3>
                <ul>
                  {Object.entries(descriptorData.days).map(([day, count]) => (
                    <li key={day}>
                      Dia {day}: {count} {count === 1 ? 'dado' : 'dados'}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* Resumo dos totais por DescriptorName */}
      <div>
        <h2>Total de Dados por DescriptorName</h2>
        {Object.entries(totalDescriptorCounts).map(([descriptor, count]) => (
          <p key={descriptor}>
            {descriptor} total / {count} {count === 1 ? 'dado' : 'dados'}
          </p>
        ))}
      </div>

      {/* Lista de alertas detalhada */}
      {alerts.length === 0 ? (
        <p>Nenhum alerta encontrado.</p>
      ) : (
        <ul>
          {alerts.map((alert, index) => (
            <li key={index}>
              <div>
                <strong>ID:</strong> {alert.Id}
              </div>
              <div>
                <strong>Arrival Date:</strong> {alert.ArrivalDate}
              </div>
              <div>
                <strong>Received Date:</strong> {alert.ReceivedDate}
              </div>
              <div>
                <strong>Descriptor Name:</strong> {alert.DescriptorName}
              </div>
              <hr />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default VisaAlertList;
