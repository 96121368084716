import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/platform/components/layout/layout';
import ChargebackalertDashboard from './components/platform/chargebackalert/dashboard/dashboard';
import ChargebackalertAlert from './components/platform/chargebackalert/alert/alert';
import ChargebackalertAlertDeveloper from './components/platform/chargebackalert/alertdeveloper/alertdeveloper';
import Upload from './components/platform/app/upload/upload';
import MainPage from './pages/main/main';
import ChargebackalertPage from './pages/chargebackalert/chargebackalert';
import Indication from './components/platform/app/indication/indication';
import Billing from './components/platform/app/billing/billing';
import BillingFinancial from './components/platform/app/billing/billingfinancial';
import Account from './components/platform/app/account/account';
import Company from './components/platform/app/company/company';
import Database from './components/platform/app/database/database';
import Login from './components/platform/app/auth/login/login';
import Register from './components/platform/app/auth/register/register';
import ProtectedRoute from './components/platform/components/protectedroute/protectedroute';

// Definindo títulos de páginas com base nas rotas
const routeTitles = {
  '/': 'Rapid',
  '/landing/product/chargebackalert': 'Alerta de Chargeback - Rapid',
  '/app/auth/login': 'Login - Rapid',
  '/app/auth/register': 'Register - Rapid',

  '/app/chargebackalert/dashboard': 'Dashboard - Alerta de Chargeback - Rapid',
  '/app/chargebackalert/alert': 'Alertas - Alerta de Chargeback - Rapid',
  '/app/indication': 'Indicação - Rapid',
  '/app/billing': 'Pagamentos - Rapid',

  '/app/admin/account': 'Contas - Rapid',
  '/app/admin/company': 'Empresas - Rapid',

  '/app/financial/billing': 'Pagamentos - Rapid',

  '/app/developer/database': 'Banco de dados - Rapid',
  '/app/developer/alert': 'Alertas - Rapid',
  '/app/developer/upload': 'Upload - Rapid',
};

// Componente Layout com lógica de título
const RouteTitle = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    const title = routeTitles[currentPath] || 'Rapid';
    document.title = title;
  }, [location.pathname]);

  return <div>{children}</div>;
};

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RouteTitle><MainPage /></RouteTitle>} />
        <Route path="/landing/product/chargebackalert" element={<RouteTitle><ChargebackalertPage /></RouteTitle>} />

        <Route path="/app/auth/login" element={<RouteTitle><Login /></RouteTitle>} />
        <Route path="/app/auth/register" element={<RouteTitle><Register /></RouteTitle>} />

        <Route path="/app/chargebackalert/dashboard" element={<ProtectedRoute allowedRoles={['member', 'admin', 'financial', 'developer']}><RouteTitle><Layout><ChargebackalertDashboard /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/chargebackalert/alert" element={<ProtectedRoute allowedRoles={['member', 'admin', 'financial', 'developer']}><RouteTitle><Layout><ChargebackalertAlert /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/indication" element={<ProtectedRoute allowedRoles={['member', 'admin', 'financial', 'developer']}><RouteTitle><Layout><Indication /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/billing" element={<ProtectedRoute allowedRoles={['member', 'admin', 'financial', 'developer']}><RouteTitle><Layout><Billing /></Layout></RouteTitle></ProtectedRoute>} />

        <Route path="/app/admin/account" element={<ProtectedRoute allowedRoles={['admin', 'financial', 'developer']}><RouteTitle><Layout><Account /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/admin/company" element={<ProtectedRoute allowedRoles={['admin', 'financial', 'developer']}><RouteTitle><Layout><Company /></Layout></RouteTitle></ProtectedRoute>} />

        <Route path="/app/financial/billing" element={<ProtectedRoute allowedRoles={['financial', 'developer']}><RouteTitle><Layout><BillingFinancial /></Layout></RouteTitle></ProtectedRoute>} />

        <Route path="/app/developer/database" element={<ProtectedRoute allowedRoles={['developer']}><RouteTitle><Layout><Database /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/developer/alert" element={<ProtectedRoute allowedRoles={['developer']}><RouteTitle><Layout><ChargebackalertAlertDeveloper /></Layout></RouteTitle></ProtectedRoute>} />
        <Route path="/app/developer/upload" element={<ProtectedRoute allowedRoles={['developer']}><RouteTitle><Layout><Upload /></Layout></RouteTitle></ProtectedRoute>} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
