import React, { useState } from 'react';
import styles from './table.module.css';
import { useTheme } from '../themecontext/ThemeContext';
import Select from '../select/select';
import { LuSearchX, LuLoader2 } from 'react-icons/lu'; // Importe o ícone que deseja usar

const App = ({ 
  columns, 
  data, 
  renderActions, 
  currentPage, 
  totalPages, 
  onPageChange, 
  totalItems, 
  itemsPerPage, 
  onItemsPerPageChange, 
  noDataMessage, // Adicione uma nova prop para a mensagem de dados indisponíveis
  isLoading, // Adicione uma nova prop para o estado de carregamento
  loadingMessage, // Adicione uma nova prop para a mensagem de carregamento
  title
}) => {
  const { theme } = useTheme(); // Pega o tema atual do contexto

  // Cálculo de startItem e endItem com base na página atual
  const startItem = 1; // Sempre inicia com 1 para a contagem de itens exibidos na página
  const endItem = data.length; // Número de itens na página atual

  // Função para gerar os números de páginas para o seletor de página
  const generatePageNumbers = () => {
    let pages = [];
    for (let i = 1; i <= (totalPages || 1); i++) { // Ajuste aqui para garantir que pelo menos uma página seja exibida
      pages.push(i);
    }
    return pages;
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.table_container}>
        <h3 className={styles.title}>{title}</h3>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headerRow}>
              {columns.map((column) => (
                <th key={column.accessor} className={styles.headerCell}>
                  {column.Header}
                </th>
              ))}
              {renderActions && <th className={styles.headerCell}>AÇÕES</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading ? ( // Verifica se está carregando
              <tr>
                <td colSpan={columns.length + (renderActions ? 1 : 0)} style={{ textAlign: 'center', padding: '20px' }}>
                  <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                    <LuLoader2 size={30} className={styles.loadingIcon} /> {/* Ícone de carregamento */}
                    <p>{loadingMessage || 'Buscando informações, por favor, aguarde...'}</p> {/* Exibe a mensagem de carregamento ou uma padrão */}
                  </div>
                </td>
              </tr>
            ) : data.length === 0 ? ( // Verifica se o array de dados está vazio
              <tr>
                <td colSpan={columns.length + (renderActions ? 1 : 0)} style={{ textAlign: 'center', padding: '20px' }}>
                  <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
                    <LuSearchX size={30} />
                    <p>{noDataMessage || 'Nenhuma informação disponível para exibição.'}</p> {/* Exibe a mensagem passada ou uma mensagem padrão */}
                  </div>
                </td>
              </tr>
            ) : (
              data.map((row, rowIndex) => (
                <tr key={row.Id || rowIndex} className={styles.bodyRow}>
                  {columns.map((column) => (
                    <td key={column.accessor} className={styles.cell}>
                      {typeof column.accessor === 'function' ? (
                        column.Cell ? column.Cell({ value: column.accessor(row), row }) : column.accessor(row) // Caso o accessor seja uma função que retorna múltiplos valores
                      ) : (
                        column.Cell ? column.Cell({ value: row[column.accessor], row }) : row[column.accessor] // Comportamento padrão para acessor como string
                      )}
                    </td>
                  ))}
                  {renderActions && (
                    <td className={styles.actionCell}>
                      {renderActions(row)}
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Contêiner de Paginação Fora da Tabela */}
      <div className={styles.pagination_container}>
        <div className={styles.paginationInfo}>
          <p>Exibindo <strong>{endItem}</strong> de <strong>{totalItems}</strong> alertas</p>
          <Select
            options={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(option => ({ value: option, label: option.toString() }))}
            value={itemsPerPage}
            onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
            name="itemsPerPageSelect"
            maxWidth="100px"
          />
        </div>
        <div className={styles.pagination_content}>
          <p>Página <strong>{currentPage}</strong> de <strong>{totalPages > 0 ? totalPages : 1}</strong></p>
          <div className={styles.paginationControls}>
            <Select
              options={generatePageNumbers().map(page => ({ value: page, label: `Página ${page}` }))}
              value={currentPage}
              onChange={(e) => onPageChange(Number(e.target.value))}
              name="pageSelect"
              maxWidth="150px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
