import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styles from './components.module.css';
import axios from 'axios';
import Table from '../../../components/table/table';
import DatePicker from '../../../components/datapicker/datapicker';
import Select from '../../../components/select/select';
import Popup from '../../../components/popup/popup';
import FilterComponent from '../../../components/filtercomponent/filtercomponent';
import { useTheme } from '../../../components/themecontext/ThemeContext';
import { BsCreditCard } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { IoMdCheckmarkCircleOutline, IoMdRefreshCircle, IoMdCloseCircleOutline, IoMdTime } from 'react-icons/io'
import { LuLoader2 } from 'react-icons/lu';
import { GrSecure } from "react-icons/gr";
import InfoPopup from '../../../components/infopopup/infopopup'

// Hook personalizado para buscar dados
const useFetchMetrics = (startDate, endDate, setOriginalData) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          'https://client.rapidchargeback.com/api/product/chargebackalert/alert/mastercard',
          {
            params: { startDate, endDate },
            headers: { Authorization: token },
          }
        );

        if (Array.isArray(response.data)) {
          setOriginalData(response.data);
        } else if (response.data && Array.isArray(response.data.data)) {
          setOriginalData(response.data.data);
        } else {
          setOriginalData([]);
          /*console.error('A resposta da API não contém um array válido:', response.data);*/
        }
      } catch (err) {
        setError('Erro ao buscar métricas');
        /*console.error('Erro ao buscar métricas:', err);*/
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, setOriginalData]);

  return { loading, error };
};

// Função para obter opções de filtro do token no localStorage
const getFilterOptionsFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return [{ label: 'Todas empresas', values: ['Todas empresas'] }];

  try {
    const base64Payload = token.split('.')[1];
    const jsonPayload = JSON.parse(atob(base64Payload));

    const filterValues = Object.keys(jsonPayload)
      .filter((key) => key.startsWith('merchant_mastercard_'))
      .map((key) => jsonPayload[key]);

    return [
      { label: 'Todas empresas', values: ['Todas empresas'] },
      ...filterValues.map((value) => ({ label: value, values: [value] }))
    ];
  } catch (error) {
    /*console.error('Erro ao decodificar o token:', error);*/
    return [{ label: 'Todas empresas', values: ['Todas empresas'] }];
  }
};

/*// Função para obter opções de filtro do token no localStorage
const getFilterOptionsFromToken2 = () => {
  const token = localStorage.getItem('token');
  if (!token) return [{ label: 'Todos comerciantes', values: ['Todos comerciantes'] }];

  try {
    const base64Payload = token.split('.')[1];
    const jsonPayload = JSON.parse(atob(base64Payload));

    const filterValues = Object.keys(jsonPayload)
      .filter((key) => key.startsWith('merchantdescriptor_mastercard_'))
      .map((key) => jsonPayload[key]);

    return [
      { label: 'Todos comerciantes', values: ['Todos comerciantes'] },
      ...filterValues.map((value) => ({ label: value, values: [value] }))
    ];
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return [{ label: 'Todos comerciantes', values: ['Todos comerciantes'] }];
  }
};*/

const App = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { theme } = useTheme();
  const { loading: isLoading, error } = useFetchMetrics(startDate, endDate, setOriginalData);
  const [status, setStatus] = useState(''); // Estado para o select de status
  const [saveButtonColor, setSaveButtonColor] = useState(''); // Estado para a cor do botão "Salvar"
  const [isSaving, setIsSaving] = useState(false); // Novo estado para o carregamento do botão

  // Atualizar opções de MerchantDescriptor dinamicamente
  const [merchantDescriptorOptions, setMerchantDescriptorOptions] = useState([
    { label: 'Todos comerciantes', values: ['Todos comerciantes'] }
  ]); // Novo estado para opções de MerchantDescriptor

  // Atualizar opções de MerchantDescriptor dinamicamente
  useEffect(() => {
    if (originalData.length > 0) {
      const uniqueDescriptors = [...new Set(originalData.map(item => item.MerchantDescriptor))];
      const descriptorOptions = [
        { label: 'Todos comerciantes', values: ['Todos comerciantes'] },
        ...uniqueDescriptors.map(value => ({ label: value, values: [value] }))
      ];

      setMerchantDescriptorOptions(descriptorOptions); // Atualiza o estado
    }
  }, [originalData]);

  // Configurações de filtros
  const filterConfigs = [
    {
      field: 'Merchant',
      type: 'select',
      options: getFilterOptionsFromToken(),
      defaultValue: 'Todas empresas',
      label: 'Empresa'
    },
    {
      field: 'MerchantDescriptor',
      type: 'select',
      options: merchantDescriptorOptions,  // Usa o estado atualizado
      defaultValue: 'Todos comerciantes',
      label: 'Comerciante'
    },
    {
      field: 'Status',
      type: 'select',
      options: [
        { label: 'Todos status', values: ['Todos status'] },
        { label: 'ACCOUNT_SUSPENDED', values: ['ACCOUNT_SUSPENDED'] },
        { label: 'OTHER', values: ['OTHER'] },
        { label: 'NOTFOUND', values: ['NOTFOUND'] },
        { label: 'ACTIVE_DISPUTE', values: ['ACTIVE_DISPUTE', null, ''] }
      ],
      defaultValue: 'Todos status',
      label: 'Status'
    },
    {
      field: 'SendStatus',
      type: 'select',
      options: [
        { label: 'Todos status', values: ['Todos status'] },
        { label: 'Enviado', values: ['Enviado'] },
        { label: 'Reenviado', values: ['Reenviado'] },
        { label: 'Erro ao enviar', values: ['Erro ao enviar'] },
        { label: 'Aguardando envio', values: ['Aguardando envio', null, ''] }
      ],
      defaultValue: 'Todos status',
      label: 'Status de envio'
    },
    {
      field: 'AlertId',
      defaultValue: '',
      placeholder: 'Digite o id do alerta',
      type: 'input',
      typeInput: 'text',
      icon: IoSearch,
      iconColor: 'gray',
      borderRadius: "10px",
      label: 'Id'
    },
    {
      field: 'CardBin',
      defaultValue: '',
      placeholder: '6 Primeiros dígitos',
      type: 'input',
      typeInput: 'text',
      icon: BsCreditCard,
      iconColor: 'gray',
      maxLength: 6,
      onlyNumbers: true,
      borderRadius: "10px",
      label: 'Bin'
    },
    {
      field: 'CardLast4',
      defaultValue: '',
      placeholder: '4 Últimos dígitos',
      type: 'input',
      typeInput: 'text',
      icon: BsCreditCard,
      iconColor: 'gray',
      maxLength: 4,
      onlyNumbers: true,
      borderRadius: "10px",
      label: 'Last4'
    },
    {
      field: 'AuthCode',
      defaultValue: '',
      placeholder: 'Digite o código de autorização',
      type: 'input',
      typeInput: 'text',
      icon: GrSecure,
      iconColor: 'gray',
      borderRadius: "10px",
      label: 'Código de autorização'
    }
  ];

  const [filters, setFilters] = useState(
    filterConfigs.map((config) => ({ field: config.field, value: config.defaultValue }))
  );

  const applyFilters = useCallback(
    (data, filters) => {
      return filters.reduce((acc, filter) => {
        const { field, value } = filter;
        const filterConfig = filterConfigs.find((config) => config.field === field);
  
        if (!filterConfig || value === filterConfig.defaultValue) return acc;
  
        if (filterConfig.type === 'input') {
          if (field === 'CardBin') {
            // Filtra o campo 'CardNumber' pelos primeiros 6 caracteres
            return value ? acc.filter(item => item.CardNumber && item.CardNumber.toString().startsWith(value)) : acc;
          } else if (field === 'CardLast4') {
            // Filtra o campo 'CardNumber' pelos últimos 4 caracteres
            return value ? acc.filter(item => item.CardNumber && item.CardNumber.toString().slice(-4) === value) : acc;
          } else {
            return value ? acc.filter(item => item[field] && item[field].toString().includes(value)) : acc;
          }
        } else if (filterConfig.type === 'select') {
          const selectedOption = filterConfig.options.find(option => option.label.toLowerCase() === value.toLowerCase());
          if (selectedOption) {
            return acc.filter(item => {
              // Tratamento especial para o campo Merchant
              if (field === 'Merchant') {
                const merchantName = item.Merchant ? item.Merchant.split(' - ')[0].toLowerCase() : ''; // Pega apenas a parte antes do hífen
                return selectedOption.values.some(val => val.toLowerCase() === merchantName);
              } else {
                // Comparação flexível e parcial para outros campos
                return selectedOption.values.some(val => {
                  // Se ambos 'val' e 'item[field]' não forem nulos ou vazios, usa toLowerCase e inclui a lógica para correspondência parcial
                  if (val && val !== "" && item[field] && item[field] !== "") {
                    return item[field].toLowerCase().includes(val.toLowerCase());
                  }
                  // Se 'val' ou 'item[field]' forem nulos ou vazios, compara diretamente
                  return val === item[field];
                });
              }
            });
          }
        }
        return acc;        
      }, data);
    },
    []
  );

  const filteredData = useMemo(() => applyFilters(originalData, filters), [originalData, filters, applyFilters]);

  // Calcular totalPages após filteredData ser definido
  const totalPages = useMemo(() => Math.ceil(filteredData.length / itemsPerPage), [filteredData, itemsPerPage]);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reinicia para a primeira página
  };

  const handleFilterChange = useCallback((field, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.field === field ? { ...filter, value } : filter
      )
    );
  }, []);

  const handleDateChange = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  const handleOpenPopup = (item) => {
    setSelectedItem(item);
    setStatus(item.Status); // Define o status inicial baseado no item selecionado
    setIsPopupOpen(true);
  };  

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedItem(null);
  };

  const handleSaveStatus = async () => {
    if (!selectedItem) return;
    
    // Verifique se o status é válido
    if (status === 'Selecione um status' || !status) {
      alert('Por favor, selecione um status válido antes de salvar.');
      return;
    }

    setIsSaving(true); // Define o estado de carregamento como verdadeiro
  
    try {
      const response = await axios.post(
        'https://api.rapidchargeback.com/alerts/update-status/master',
        {
          AlertId: selectedItem.AlertId,
          Status: status,
        },
        {
          headers: {
            ClientId: 'c342dff23-2c3e-rc43-43r3-23v45vst4532',
            ClientKey: '432tv5324vtresgvbrfsdb54g5t34d5t345f',
          },
        }
      );
  
      if (response.status === 200) {
        setSaveButtonColor('rgb(0, 168, 0)'); // Altera a cor do botão para verde
        setTimeout(() => {
          setSaveButtonColor(''); // Volta o botão à cor normal após 1 segundo
          setIsPopupOpen(false); // Fecha o popup após 1 segundo
        }, 1000);
      }
    } catch (error) {
      /*console.error('Erro ao atualizar o status:', error);*/
      setSaveButtonColor('red'); // Altera a cor do botão para vermelho
      setTimeout(() => {
        setSaveButtonColor(''); // Volta o botão à cor normal após 1 segundo
      }, 1000);
    } finally {
      setIsSaving(false); // Define o estado de carregamento como falso
    }
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return 'Data inválida'; // Verifica se a data é nula ou vazia
    
    const date = new Date(dateStr);
    if (isNaN(date)) return 'Data inválida';
  
    // Formata a data para o fuso horário UTC
    const localDate = date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  
    // Verifica se a string original contém informações de hora
    const hasTime = typeof dateStr === 'string' && (dateStr.includes('T') || dateStr.includes(' '));
  
    // Extrai a hora somente se existir no formato original
    const utcTime = hasTime ? date.toISOString().slice(11, 19) : '';
  
    // Formata a data para o fuso horário local do usuário
    const localFormattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const localTime = hasTime ? date.toLocaleTimeString('pt-BR') : '';
  
    return (
      <>
        <div>
          {`${localDate}${hasTime ? ` ${utcTime}` : ''} `}
          <small>UTC</small>
        </div>
        <div style={{ fontSize: '13px', marginTop: '8px' }}>
          {`${localFormattedDate}${hasTime ? ` ${localTime}` : ''} `}
          <small>Local</small>
        </div>
      </>
    );
  };

  const paginatedData = useMemo(
    () => filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [filteredData, currentPage, itemsPerPage]
  );

  const handleOpenFilterPopup = () => setIsFilterPopupOpen(true);
  const handleCloseFilterPopup = () => setIsFilterPopupOpen(false);

  // Definindo o popupFieldsConfig
  const popupFieldsConfig = {
    AlertId: { label: 'Id', defaultValue: 'Desconhecido' },
    Type: { label: 'Tipo', defaultValue: 'Desconhecido' },
    Merchant: { label: 'Empresa', defaultValue: 'Desconhecido' },
    MerchantDescriptor: { label: 'Comerciante', defaultValue: 'Desconhecido' },
    AuthCode: { label: 'Código de Autorização', defaultValue: 'Desconhecido' },
    Status: { label: 'Status', defaultValue: 'ACTIVE_DISPUTE' },
    CardNumber: { label: 'Número do cartão', defaultValue: 'Desconhecido' },
    Source: { label: 'Fonte', defaultValue: 'Desconhecido' },
    TransactionType: { label: 'Tipo de pagamento', defaultValue: 'Desconhecido' },
    Amount: { label: 'Valor da transação', defaultValue: 'Desconhecido' },
    TransactionDate: { label: 'Data da transação', defaultValue: 'Desconhecido' },
    ArrivalDate: { label: 'Data de recebimento', defaultValue: 'Desconhecido' },
    Issuer: { label: 'Emissora', defaultValue: 'Desconhecido' },
    Country: { label: 'País', defaultValue: 'Desconhecido' },
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.filter_container}>
        <DatePicker onDateChange={handleDateChange} />
        <button onClick={handleOpenFilterPopup} className={styles.button_popup}>+ Filtros</button>
      
        {/* Popup para Filtros */}
        <Popup
          isOpen={isFilterPopupOpen}
          onClose={handleCloseFilterPopup}
          title="Filtros"
          firstChild={
            <div className={styles.filter_container_popup}>
              {filterConfigs.map((config) => (
                <FilterComponent
                  key={config.field}
                  config={config}
                  value={filters.find((filter) => filter.field === config.field)?.value}
                  onChange={handleFilterChange}
                />
              ))}
            </div>
          }
          secondChild={null}
          maxWidth="600px"
        />
      </div>
      <InfoPopup
        data={filteredData}
        fieldName="SendStatus"
        popupTitle="Status de Envio"
        iconMapping={{
          'Enviado': IoMdCheckmarkCircleOutline,
          'Reenviado': IoMdRefreshCircle,
          'Erro ao enviar': IoMdCloseCircleOutline,
          'Aguardando envio': IoMdTime,
        }}
      />
      <Table
        columns={[
          {
            Header: 'ID',
            accessor: 'AlertId',
            Cell: ({ value }) => value || 'Desconhecido',
          },
          {
            Header: 'EMPRESA',
            accessor: 'Merchant',
            Cell: ({ value }) => {
              // Verifica se o valor é uma string e contém um hífen
              if (typeof value === 'string' && value.includes(' - ')) {
                return value.split(' - ')[0].toUpperCase(); // Retorna a parte antes do hífen em maiúsculas
              }
              return (value || 'Desconhecido').toUpperCase(); // Retorna o valor em maiúsculas ou 'Desconhecido'
            },
          },
          {
            Header: 'COMERCIANTE',
            accessor: 'MerchantDescriptor',
            Cell: ({ value }) => value || 'Desconhecido',
          },
          {
            Header: 'STATUS',
            accessor: 'Status',
            Cell: ({ value }) => value || 'ACTIVE_DISPUTE',
          },
        ]}
        data={paginatedData}
        renderActions={(row) => (
          <button className={styles.button_popup} onClick={() => handleOpenPopup(row)}>
            Ver detalhes
          </button>
        )}
        currentPage={currentPage}
        totalPages={totalPages} // Usa o totalPages calculado dinamicamente
        onPageChange={setCurrentPage}
        totalItems={filteredData.length}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
        noDataMessage="Nenhum alerta disponível para exibição."
        isLoading={isLoading}
        loadingMessage="Buscando alertas, por favor, aguarde..."
      />
      <Popup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title="Detalhes do alerta"
        firstChild={
          selectedItem && (
            <div className={styles.grid}>
              {Object.entries(popupFieldsConfig).map(([field, { label, defaultValue }]) => (
                <div key={field} className={styles.grid_item}>
                  <h4>{label}</h4>
                  <p>
                    {formatDate(selectedItem[field])
                      ? field.includes('Date')
                        ? formatDate(selectedItem[field])
                        : field === 'Merchant' // Verifica se o campo é 'Merchant'
                        ? // Aplica a lógica para mostrar apenas a parte antes do hífen e em maiúsculas
                          (typeof selectedItem[field] === 'string' && selectedItem[field].includes(' - ') 
                            ? selectedItem[field].split(' - ')[0].toUpperCase() 
                            : selectedItem[field].toUpperCase())
                        : selectedItem[field] // Para outros campos, exibe o valor normal
                      : defaultValue}
                  </p>
                </div>
              ))}
            </div>
          )
        }
        secondChild={
          selectedItem && (
            <div className={styles.action_container}>
              <Select
                options={[
                  { value: 'Selecione um status', label: 'Selecione um status' },
                  { value: 'ACCOUNT_SUSPENDED', label: 'ACCOUNT_SUSPENDED' },
                  { value: 'OTHER', label: 'OTHER' },
                  { value: 'NOTFOUND', label: 'NOTFOUND' },
                ]}
                value={status || 'Selecione um status'}
                onChange={(e) => setStatus(e.target.value)}
                maxWidth='210px'
              />
              <button
                className={`${styles.button_save} ${
                  saveButtonColor === 'rgb(0, 168, 0)'
                    ? styles.button_success
                    : ''
                } ${saveButtonColor === 'red' ? styles.button_error : ''}`}
                onClick={handleSaveStatus}
                disabled={status === 'Selecione um status' || !status || isSaving} // Desabilita o botão quando o status for inválido ou enquanto está salvando
              >
                {isSaving ? (
                  <LuLoader2 className={styles.loadingIcon} size={15}/> // Exibe o ícone de carregamento
                ) : (
                  'Salvar' // Texto padrão do botão
                )}
              </button>
            </div>
          )
        }
        maxWidth="650px"
      />
    </div>
  );
};

export default App;
