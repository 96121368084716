import React from 'react';
import styles from './panel.module.css';
import { useTheme } from '../themecontext/ThemeContext';

const App = ({ title, description }) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};

export default App;