import React from 'react';
import styles from './account.module.css';
import Header from '../../components/panel/panel';
import RegisterForm from './components/register';
import AccountList from './components/list';

const App = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content_header}>
        <Header 
          title="Contas"
          description="Painel de gestão para administrar contas e permissões."
        />
      </div>
      <div className={styles.content}>
        <h2>Registrar Nova Conta</h2>
        <RegisterForm />
        <h2>Contas Existentes</h2>
        <AccountList />
      </div>
    </div>
  );
};

export default App;
