import React, { useState, useEffect } from 'react';
import styles from './indication.module.css';
import Header from '../../components/panel/panel';
import { useTheme } from '../../components/themecontext/ThemeContext';

const App = () => {
  const [referralLink, setReferralLink] = useState('');
  const { theme } = useTheme();

  useEffect(() => {
    // Recupera o token do localStorage
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Decodifica o token (assumindo que é um JSON)
        const parsedToken = JSON.parse(atob(token.split('.')[1]));
        const referralCode = parsedToken.referral_code;
        if (referralCode) {
          // Constroi o link de indicação
          setReferralLink(`https://rapidchargeback.com/app/auth/register?rdcbrc=${referralCode}`);
        }
      } catch (error) {
        // console.error('Erro ao decodificar o token', error);
      }
    }
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      alert('Link copiado para a área de transferência!');
    }).catch(err => {
      // console.error('Erro ao copiar o link: ', err);
    });
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.content_header}>
        <Header 
          title="Indicação"
          description="Painel de gestão para acompanhar e monitorar suas indicações."
        />
      </div>
      <div>
        {referralLink && (
          <div className={styles.referral_section}>
            <h3>Seu link de indicação</h3>
            <div>
              <div className={styles.link_container}>
                <p>{referralLink}</p>
              </div>
              <button onClick={handleCopyLink} className={styles.copy_button}>
                Copiar Link
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
