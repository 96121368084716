import React from 'react';
import styles from './campany.module.css'
import Header from '../../components/panel/panel'
import RegisterForm from './components/register'
import CompanyList from './components/list'

const App = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content_header}>
        <Header 
          title="Empresas"
          description="Painel único para acompanhar e gerenciar as empresas parceiras."
        />
                <RegisterForm />
      </div>
      <div>
        <CompanyList />
      </div>
    </div>
  );
};

export default App;
