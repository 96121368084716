import React, { useState } from 'react';
import styles from './sidebar.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiAlertTriangle } from "react-icons/fi";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineHandshake, MdOutlineAdminPanelSettings } from "react-icons/md";
import { WiMoonAltWaningCrescent5, WiMoonAltWaningGibbous2 } from "react-icons/wi";
import { FaLaptopCode } from "react-icons/fa";
import { TbLogout2, TbFileInvoice } from "react-icons/tb";
import { AiOutlineDollar } from "react-icons/ai";
import { jwtDecode } from 'jwt-decode'; 
import Logo from '../../../../images/platform/Logo.svg';
import Logo2 from '../../../../images/platform/Logo2.svg';
import { useTheme } from '../themecontext/ThemeContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import '../../../tooltip/tooltipstyles.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const token = localStorage.getItem('token');
  const userRole = token ? jwtDecode(token).role : null;

  const [dropdownOpen, setDropdownOpen] = useState({
    1: location.pathname.startsWith('/app/chargebackalert'),
    2: location.pathname.startsWith('/app/admin'),
    3: location.pathname.startsWith('/app/financial'),
    4: location.pathname.startsWith('/app/developer'),
  });  

  const isActive = (path) => location.pathname === path;

  const toggleDropdown = (index) => {
    setDropdownOpen((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <>
      <div className={`${styles.sidebar} ${styles[theme]} ${isOpen ? styles.sidebarOpen : ''}`}>
        <div className={styles.header}>
          {theme === 'light' ? (
            <img src={Logo} alt='Logo'/>
          ) : (
            <img src={Logo2} alt='Logo'/>
          )}
        </div>
        <div className={styles.menu}>
          {/* Botões dropdown */}
          <div className={`${styles.menuItem} ${dropdownOpen[1] ? styles.active : ''}`} onClick={() => toggleDropdown(1)}>
            <span className={styles.icon}><FiAlertTriangle size={18} /></span>
            <span>Alerta de Chargeback</span>
            <span className={`${styles.arrow} ${dropdownOpen[1] ? styles.arrowUp : ''}`}><IoIosArrowUp size={18} /></span>
          </div>
          {dropdownOpen[1] && (
            <div className={styles.dropdown}>
              <div className={`${styles.dropdownItem} ${isActive('/app/chargebackalert/dashboard') ? styles.dropdownItem_selected : ''}`} onClick={() => navigate('/app/chargebackalert/dashboard')}>Dashboard</div>
              <div className={`${styles.dropdownItem} ${isActive('/app/chargebackalert/alert') ? styles.dropdownItem_selected : ''}`} style={{ borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px'}} onClick={() => navigate('/app/chargebackalert/alert')}>Alertas</div>
            </div>
          )}

          {/* Botões normais */}
          <div className={`${styles.menuItem} ${isActive('/app/indication') ? styles.menuItem_selected : ''}`} onClick={() => navigate('/app/indication')}>
            <span className={styles.icon}><MdOutlineHandshake size={18}/></span>
            <span>Indicação</span>
          </div>
          <div className={`${styles.menuItem} ${isActive('/app/billing') ? styles.menuItem_selected : ''}`} onClick={() => navigate('/app/billing')}>
            <span className={styles.icon}><TbFileInvoice size={18}/></span>
            <span>Pagamentos</span>
          </div>

          {(userRole === 'admin' || userRole === 'financial' || userRole === 'developer') && (
            <>
              <div className={`${styles.menuItem} ${dropdownOpen[2] ? styles.active : ''}`} onClick={() => toggleDropdown(2)}>
                <span className={styles.icon}><MdOutlineAdminPanelSettings size={18}/></span>
                <span>Admin</span>
                <span className={`${styles.arrow} ${dropdownOpen[2] ? styles.arrowUp : ''}`}><IoIosArrowUp size={18} /></span>
              </div>
              {dropdownOpen[2] && (
                <div className={styles.dropdown}>
                  <div className={`${styles.dropdownItem} ${isActive('/app/admin/account') ? styles.dropdownItem_selected : ''}`} onClick={() => navigate('/app/admin/account')}>Contas</div>
                  <div className={`${styles.dropdownItem} ${isActive('/app/admin/company') ? styles.dropdownItem_selected : ''}`} style={{ borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px'}} onClick={() => navigate('/app/admin/company')}>Empresas</div>
                </div>
              )}
            </>
          )}

          {(userRole === 'financial' || userRole === 'developer') && (
            <>
              <div className={`${styles.menuItem} ${dropdownOpen[3] ? styles.active : ''}`} onClick={() => toggleDropdown(3)}>
                <span className={styles.icon}><AiOutlineDollar size={18}/></span>
                <span>Financeiro</span>
                <span className={`${styles.arrow} ${dropdownOpen[3] ? styles.arrowUp : ''}`}><IoIosArrowUp size={18} /></span>
              </div>
              {dropdownOpen[3] && (
                <div className={styles.dropdown}>
                  <div className={`${styles.dropdownItem} ${isActive('/app/financial/billing') ? styles.dropdownItem_selected : ''}`} style={{ borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px'}} onClick={() => navigate('/app/financial/billing')}>Pagamentos</div>
                </div>
              )}
            </>
          )}

          {userRole === 'developer' && (
            <>
              <div className={`${styles.menuItem} ${dropdownOpen[4] ? styles.active : ''}`} onClick={() => toggleDropdown(4)}>
                <span className={styles.icon}><FaLaptopCode size={18}/></span>
                <span>Desenvolvedor</span>
                <span className={`${styles.arrow} ${dropdownOpen[4] ? styles.arrowUp : ''}`}><IoIosArrowUp size={18} /></span>
              </div>
              {dropdownOpen[4] && (
                <div className={styles.dropdown}>
                  <div className={`${styles.dropdownItem} ${isActive('/app/developer/database') ? styles.dropdownItem_selected : ''}`} onClick={() => navigate('/app/developer/database')}>Banco de dados</div>
                  <div className={`${styles.dropdownItem} ${isActive('/app/developer/alert') ? styles.dropdownItem_selected : ''}`} onClick={() => navigate('/app/developer/alert')}>Alertas</div>
                  <div className={`${styles.dropdownItem} ${isActive('/app/developer/upload') ? styles.dropdownItem_selected : ''}`} style={{ borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px'}} onClick={() => navigate('/app/developer/upload')}>Upload</div>
                </div>
              )}
            </>
          )}
        </div>

        {/* Botão para mudar o tema */}
        <div className={styles.footer}>
          <Tippy content={theme === 'light' ? 'Modo Escuro' : 'Modo Claro'} className="customtooltip">
            <button onClick={toggleTheme} className={styles.themeToggle}>
              {theme === 'light' ? <WiMoonAltWaningCrescent5 size={24} /> : <WiMoonAltWaningGibbous2 size={24} />}
            </button>
          </Tippy>
          <Tippy content="Desconectar" className="customtooltip">
            <button onClick={() => {localStorage.removeItem('token'); navigate('/app/auth/login');}} className={styles.logout}><TbLogout2 size={24}/></button>
          </Tippy>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
