import React, { useEffect, useState } from 'react';
import customAxios from '../../../../../utils/customaxios';

const VisaAlerts = () => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [filterEnabled, setFilterEnabled] = useState(false); // Novo estado para controle de filtro

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customAxios.get('https://client.rapidchargeback.com/api/product/chargebackalert/alert/visa/verifi/all', {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        setAlerts(response.data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeleteFiltered = async () => {
    setDeleteStatus(null); // Reset delete status

    // Filtra os alertas onde alert.Tier é "" ou null
    const alertsToDelete = alerts.filter(alert => alert.tier === "" || alert.tier === null || alert.tier === "null");

    if (alertsToDelete.length === 0) {
      setDeleteStatus('Nenhum alerta encontrado com a condição especificada.');
      return;
    }

    for (const alert of alertsToDelete) {
      try {
        const response = await customAxios.delete(`https://client.rapidchargeback.com/api/product/chargebackalert/alert/visa/verifi/delete/${alert.case_id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        console.log(`Deleted alert with ID ${alert.case_id}:`, response.data);
      } catch (err) {
        console.error(`Error deleting alert with ID ${alert.case_id}:`, err.message);
        setDeleteStatus(`Erro ao deletar o alerta com ID ${alert.case_id}`);
        return;
      }
    }

    setDeleteStatus('Alertas filtrados foram deletados com sucesso.');
    setAlerts(alerts.filter(alert => !(alert.Tier === "" || alert.Tier === null))); // Remove os alertas deletados da lista
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>Erro: {error}</p>;
  }

  return (
    <div>
      <h1>Alertas VISA</h1>
      <button onClick={handleDeleteFiltered}>Deletar Alertas Filtrados</button>
      {deleteStatus && <p>{deleteStatus}</p>}
      <ul>
        {alerts.map((alert, index) => (
          <li key={index}>
            <pre>{JSON.stringify(alert, null, 2)}</pre>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VisaAlerts;
