import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../../../../images/platform/logo.png'; // Caminho para sua logo PNG

const formatCurrency = (value) => {
  if (!value) return 'R$ 0,00';
  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const formatDate = (dateStr) => {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-');
  return `${day}/${month}/${year}`; // Remonta a data no formato 'DD/MM/YYYY'
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff', // Fundo branco
    padding: 40,
    fontFamily: 'Helvetica',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid #eee',
    marginBottom: 20,
    paddingBottom: 10,
  },
  logo: {
    width: 100,
    height: 'auto',
  },
  companyInfo: {
    textAlign: 'right',
    fontSize: 10,
    color: '#333',
  },
  section: {
    marginBottom: 15,
    padding: 15,
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius: 8,
    border: '1px solid #f9f9f9',
  },
  sectionWithColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
    padding: 15,
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius: 8,
    border: '1px solid #f9f9f9',
  },
  column2: {
    width: '50%',
  },
  column3: {
    width: '48%',
  },
  field: {
    fontSize: 10,
    color: '#666',
    marginBottom: 3,
  },
  value: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000',
    marginBottom: 8,
  },
  totalValue: {
    fontSize: 14, // Reduzido para diferenciar do valor total da fatura
    fontWeight: 'bold',
    color: '#444', // Cor neutra para diferenciar
  },
  footer: {
    marginTop: 20,
    textAlign: 'center',
    fontSize: 9,
    color: '#888',
    borderTop: '1px solid #ddd',
    paddingTop: 8,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 8,
    borderBottom: '1px solid #ddd',
    paddingBottom: 5,
  },
  highlight: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#2e7d32', // Destaque em verde para o valor total da fatura
    marginVertical: 5,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    marginBottom: 8,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign: 'left',
  },
  tableCellBold: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'left',
  },
});

const InvoicePDF = ({ invoiceData }) => (
  <Document>
    <Page style={styles.page}>
      {/* Cabeçalho */}
      <View style={styles.header}>
        <Image src={Logo} style={styles.logo} />
        <View>
          <Text style={styles.companyInfo}>rapidchargeback.com</Text>
          <Text style={styles.companyInfo}>financeiro@rapidchargeback.com</Text>
          <Text style={styles.companyInfo}>+55 (21) 96587-0724</Text>
        </View>
      </View>

      {/* Resumo da Fatura */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Resumo da Fatura</Text>
        <Text style={styles.field}>ID da Fatura</Text>
        <Text style={styles.value}>{invoiceData.invoiceId || 'Desconhecido'}</Text>
        <Text style={styles.field}>Período de Cobrança</Text>
        <Text style={styles.value}>{`${formatDate(invoiceData.startDate)} - ${formatDate(invoiceData.endDate)}` || 'Desconhecido'}</Text>
        <Text style={styles.field}>Total de Alertas</Text>
        <Text style={styles.value}>{invoiceData.totalAlerts || 0}</Text>
        <Text style={styles.field}>Valor Total</Text>
        <Text style={styles.highlight}>{formatCurrency(invoiceData.totalAmount)}</Text> {/* Valor total da fatura em destaque */}
      </View>

      {/* Detalhes Visa e Mastercard */}
      <View style={styles.sectionWithColumns}>
        {/* Visa */}
        <View style={styles.column2}>
          <Text style={styles.sectionTitle}>Visa</Text>
          {/* Tabela de Tiers da Visa */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Tier</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Qtd.</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Valor</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Preço (un)</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Tier 1</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{invoiceData.visaTier1Alerts || 0}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier1Amount)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier1Price)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Tier 2</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{invoiceData.visaTier2Alerts || 0}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier2Amount)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier2Price)}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Tier 3</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{invoiceData.visaTier3Alerts || 0}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier3Amount)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{formatCurrency(invoiceData.visaTier3Price)}</Text>
              </View>
            </View>
          </View>
          <Text style={styles.field}>Qtd. Total</Text>
          <Text style={styles.value}>{invoiceData.totalVisaAlerts || 0}</Text>
          <Text style={styles.field}>Valor Total</Text>
          <Text style={styles.totalValue}>{formatCurrency(invoiceData.totalVisaAmount)}</Text>
        </View>

        {/* Mastercard */}
        <View style={styles.column3}>
          <Text style={styles.sectionTitle}>Mastercard</Text>
          <Text style={styles.field}>Qtd. Total</Text>
          <Text style={styles.value}>{invoiceData.totalMastercardAlerts || 0}</Text>
          <Text style={styles.field}>Preço (un)</Text>
          <Text style={styles.value}>{formatCurrency(invoiceData.mastercardAlertPrice)}</Text>
          <Text style={styles.field}>Valor Total</Text>
          <Text style={styles.totalValue}>{formatCurrency(invoiceData.totalMastercardAmount)}</Text>
        </View>
      </View>

      {/* Detalhes do Pagador */}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>Detalhes do Pagador</Text>
        <Text style={styles.field}>Razão Social</Text>
        <Text style={styles.value}>{invoiceData.company_Name || 'Desconhecido'}</Text>
        <Text style={styles.field}>CNPJ</Text>
        <Text style={styles.value}>{invoiceData.companyCnpj || 'Desconhecido'}</Text>
        <Text style={styles.field}>Email</Text>
        <Text style={styles.value}>{invoiceData.companyEmail || 'Desconhecido'}</Text>
      </View>

      {/* Rodapé */}
      <Text style={styles.footer}>
        {`Fatura ID: ${invoiceData.invoiceId} • Valor Total: ${formatCurrency(invoiceData.totalAmount)} • Período: ${formatDate(invoiceData.startDate)} - ${formatDate(invoiceData.endDate)}`}
      </Text>
    </Page>
  </Document>
);

export default InvoicePDF;
