import React, { useState } from 'react';
import styles from './billing.module.css';
import Header from '../../components/panel/panel';
import InvoiceList from './components/chargebackalert/invoices/list'
import Select from '../../components/select/select';
import Message from '../../components/notice/notice'

const App = () => {
  const [selectedOption, setSelectedOption] = useState('chargebackalert'); // Estado para controlar a opção selecionada

  // Opções para o componente Select
  const options = [
    { value: 'chargebackalert', label: 'Alerta de Chargeback' },
  ];

  // Função para lidar com a mudança de seleção
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className={styles.container}>
      <Message
        title="Notas:"
        description="Todos os alertas são registrados e exibidos com base na data e hora em UTC (Tempo Universal Coordenado). O faturamento é calculado considerando essa mesma referência. Ao buscar alertas ou revisar faturas, ajuste seu entendimento para o horário UTC."
      />
      <div className={styles.content_header}>
        <Header 
          title="Pagamentos"
          description="Painel para acompanhar e gerenciar pagamentos."
        />
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectChange}
          name="productType"
          borderRadius="5px"
          maxWidth="200px"
        />
      </div>
      <div>
        {selectedOption === 'chargebackalert' ? <InvoiceList /> : <InvoiceList />}
      </div>
    </div>
  );
};

export default App;