import React, {useEffect} from 'react';
import styles from "./main.module.css"

import Shop from '../../images/landing/icon/shop.svg'
import Finance from '../../images/landing/icon/finance.svg'
import Chip from '../../images/landing/icon/chip.svg'
import Service from '../../images/landing/icon/service.svg'
import Company from '../../images/landing/icon/company.svg'

import Dashboard from '../../images/landing/main/MainImageMobile.png'
import MiddlePerson from '../../images/landing/main/MiddlePerson.png'
import FooterPerson from '../../images/landing/main/FooterPerson.png'
import Mouser from "../../images/landing/main/mouser.svg"

import Header from '../../components/landing/header/header'
import Esteira from '../../components/landing/belt/belt'
import Cards from "../../components/landing/cardbutton/cardbutton"
import Segments from "../../components/landing/segments/segments"
import Form from '../../components/landing/form/form'
import Footer from '../../components/landing//footer/footer'

const App = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleContatoClick = () => {
        const section = document.getElementById('Contato');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

return (
    <div className={styles.container}>
        <div className={styles.Header}>
            <Header/>
        </div>
        <div className={styles.Main}>
            <div className={styles.MainContainer}>
                <div className={styles.MainTexts}>
                    <h1>A solução definitiva em segurança digital e proteção de dados contra fraudes online!</h1>
                    <h2>Aumente suas conversões e reduza os riscos com nossas soluções avançadas!</h2>
                    <div>
                        <button style={{ marginTop: '25px' }} className={styles.mainbutton} onClick={handleContatoClick}>Fale com especialistas</button>
                    </div>
                </div>
                <div className={styles.MainMobileImage}>
                    <img draggable= 'false' src={Dashboard}/>
                </div>
            </div>
            <div className={styles.MainMouserImage}>
                <img draggable= 'false' src={Mouser}/>
            </div>
        </div>
        <div className={styles.MiddleEmpresas}>
            <Esteira/>
        </div>
        <div id='Solucoes' className={styles.MiddleSolucoes}>
            <h3 className={styles.titleblack}>Conheça nossas soluções</h3>
            <div className={styles.SolucoesCards}>
                <Cards
                    title="Alerta de Chargeback"
                    text="Evite 90% dos chargebacks e proteja-se contra multas e bloqueios nas adquirentes. Reduza o índice de chargeback e mantenha sua operação segura e eficiente."
                    buttonLink="/landing/product/chargebackalert"
                />
                <Cards
                    title="Anti Fraude Transacional"
                    text="Previna fraudes em transações e pagamentos de forma abrangente, seja via boleto, cartão de crédito, PIX. Proteja sua empresa contra fraudes enquanto maximiza as conversões."
                    buttonLink="URL_DO_SEU_BOTAO"
                    isDisabled={true}
                />
                <Cards
                    title="Card Check Secure"
                    text="Garanta a identidade e titularidade do cartão de crédito no momento da compra. Ofereça uma experiência segura e tranquila aos seus clientes, reduzindo o risco de fraudes."
                    buttonLink="URL_DO_SEU_BOTAO"
                    isDisabled={true}
                />
                <Cards
                    title="Onboarding e KYC"
                    text="Verifique a identidade e os dados de clientes e empresas para prevenção de fraudes. Simplifique os processos de cadastro e mantenha sua base de clientes segura."
                    buttonLink="URL_DO_SEU_BOTAO"
                    isDisabled={true}
                />
            </div>
        </div>
        <div id='Sobrenos' className={styles.MiddleExemplos}>
            <div className={styles.MiddleExemplosTexts}>
                <h3 className={styles.titleblack}>A principal escolha contra fraudes</h3>
                <p className={styles.paragraphblack}>Alcance o sucesso com o poderoso banco de dados da Rapid</p>
            </div>
            <div className={styles.MiddleExemplosPlate}>
                <div>
                    <div>
                        <h4>+90,4%</h4>
                        <p><strong>De CPF´S</strong> conhecidos<br/>em nosso datalake</p>
                    </div>
                    <div>
                        <h4>64 MI</h4>
                        <p>de <strong>pedidos</strong><br/>analisados</p>
                    </div>
                    <div>
                        <h4>1,7 MI</h4>
                        <p>de fraudes<br/><strong>evitadas</strong></p>
                    </div>
                    <div>
                        <h4>+R$1,5 BI</h4>
                        <p>em <strong>fraudes</strong><br/><strong>evitadas</strong></p>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.MiddleSegmentos}>
            <div className={styles.MiddleSegmentosImage}>
                <img draggable= 'false' src={MiddlePerson}/>
            </div>
            <div className={styles.MiddleSegmentosContainer}>
                <div className={styles.MiddleSegmentosTop}>
                    <h3 style={{ marginBottom: '20px' }} className={styles.titleblack}>Segmento de atuação</h3>
                    <p className={styles.paragraphblack}>Descubra como nossas soluções podem impulsionar a segurança e confiança em seu setor, oferecendo verificação e validação de identidade do usuário.</p>
                </div>
                <div className={styles.MiddleSegmentosMiddle}>
                    <Segments
                        img={Shop}
                        title="Empresas de E-commerce"
                        text="Empresas que realizam vendas online e processam transações eletrônicas, enfrentando desafios como chargebacks, fraudes de cartão de crédito e roubo de identidade."
                    />
                    <Segments
                        img={Finance}
                        title="Instituições Financeiras"
                        text="Bancos, instituições de pagamento, fintechs e outras empresas do setor financeiro que precisam proteger suas operações contra fraudes financeiras e garantir a segurança das transações dos clientes."
                    />
                    <Segments
                        img={Chip}
                        title="Empresas de Tecnologia e Startups"
                        text="Empresas inovadoras que oferecem serviços digitais, aplicativos móveis, plataformas de pagamento ou qualquer tipo de serviço online e precisam garantir a segurança dos usuários e a integridade das transações."
                    />
                    <Segments
                        img={Service}
                        title="Empresas de Serviços Online"
                        text="Empresas que oferecem serviços online, como streaming de vídeo, entrega de alimentos, reservas de viagens, entre outros, que precisam proteger as transações dos clientes e evitar fraudes."
                    />
                    <Segments
                        img={Company}
                        title="Empresas de Telefonia e Telecomunicações"
                        text="Empresas que oferecem serviços de telefonia móvel, internet e comunicação, enfrentando desafios relacionados a fraudes de identidade, clonagem de cartões SIM e outros tipos de fraudes online."
                    />
                </div>
                <div className={styles.MiddleSegmentosBottom}>
                    <p style={{ marginBottom: '50px' }} className={styles.paragraphblack}>Essas são apenas algumas das indústrias que podem se beneficiar das soluções de segurança digital da Rapid. As soluções são altamente adaptáveis e podem ser personalizadas para atender às necessidades específicas de uma ampla variedade de setores e empresas.</p>
                    <button className={styles.mainbutton} onClick={handleContatoClick}>Falar com especialistas</button>
                </div>
            </div>
        </div>
        <div id='Contato' className={styles.MiddleForm}>
            <Form/>
            <div className={styles.MiddleFormImage}>
                <img draggable= 'false' src={FooterPerson}/>
            </div>
        </div>
        <div className={styles.Footer}>
            <Footer/>
        </div>
    </div>
  );
};

export default App;