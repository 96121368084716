import React from 'react';
import Select from '../select/select';
import Input from '../input/input';
import styles from './filtercomponent.module.css';

const App = ({ config, value, onChange }) => {
  const { field, label, type, options, placeholder, typeInput, maxWidth, onlyNumbers, maxLength, iconColor, iconSize, icon, borderRadius } = config;

  const handleChange = (e) => {
    onChange(field, e.target.value);
  };

  return (
    <div className={styles.filter_item}>
      {label && <label>{label}</label>}
      {type === 'select' ? (
        <Select
          options={options.map((option) => ({ value: option.label, label: option.label }))}
          value={value}
          onChange={handleChange}
          name={`filter-${field}`}
          maxWidth='200px'
        />
      ) : (
        <Input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          name={`filter-${field}`}
          maxWidth={maxWidth}
          type={type}
          typeInput={typeInput}
          icon={icon}
          iconSize={iconSize}
          iconColor={iconColor}
          maxLength={maxLength}
          onlyNumbers={onlyNumbers}
          borderRadius={borderRadius}
        />
      )}
    </div>
  );
};

export default App;