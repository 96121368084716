import React from 'react';
import styles from './alertdeveloper.module.css'
import Header from '../../components/panel/panel'
import AlertVisa from './components/visa'

const App = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content_header}>
        <Header 
          title="Alertas desenvolvedor"
          description="Monitore e gerencie todos os alertas em um único painel."
        />
      </div>
      <div>
        <AlertVisa/>
      </div>
    </div>
  );
};

export default App;
