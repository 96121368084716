import React, { useState } from 'react';
import axios from 'axios';

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'member',  // Tente mudar este valor para testar
    telephone: '',
    key: 'X345UY'
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://client.rapidchargeback.com/api/app/auth/register', formData, {
        headers: {
          'Authorization': 'FHYU8H4PRAPID2023CHARGEBACKFHYU8H4P',
        },
      });
      alert('Registro bem-sucedido!');
    } catch (error) {
      // console.error('Erro ao registrar:', error.response?.data || error.message);  // Verifique os detalhes do erro
      alert('Erro ao registrar: ' + error.response?.data || error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="name" placeholder="Nome" value={formData.name} onChange={handleChange} required />
      <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
      <input type="password" name="password" placeholder="Senha" value={formData.password} onChange={handleChange} required />
      <input type="text" name="telephone" placeholder="Telefone" value={formData.telephone} onChange={handleChange} required />
      <select name="role" value={formData.role} onChange={handleChange}>
        <option value="member">Membro</option>
        <option value="admin">Admin</option>
        <option value="financial">Financeiro</option>
        <option value="developer">Desenvolvedor</option>
      </select>
      <button type="submit">Registrar</button>
    </form>
  );
};

export default App;
