import React from 'react';
import styles from './input.module.css';
import { useTheme } from '../themecontext/ThemeContext';

const Input = ({
    value = '',
    onChange,
    placeholder,
    maxWidth,
    name,
    typeInput = 'text',
    icon: Icon,
    iconSize = 15,
    iconColor = 'gray',
    maxLength,  // Propriedade para o número máximo de caracteres
    onlyNumbers = false,  // Propriedade para permitir apenas números
    onlyNumbersAndDots = false,  // Nova propriedade para permitir apenas números e pontos
    borderRadius = '10px',  // Propriedade para definir o border-radius
}) => {
    const { theme } = useTheme();

    // Função de validação para garantir que apenas números ou números e pontos sejam digitados
    const handleChange = (event) => {
        let newValue = event.target.value;

        if (onlyNumbers) {
            // Remove todos os caracteres que não são dígitos
            newValue = newValue.replace(/\D/g, '');
        }

        if (onlyNumbersAndDots) {
            // Remove todos os caracteres que não são dígitos ou pontos
            newValue = newValue.replace(/[^0-9.]/g, '');
            
            // Certifica-se de que não há mais de um ponto
            const parts = newValue.split('.');
            if (parts.length > 2) {
                newValue = parts[0] + '.' + parts.slice(1).join('');
            }

            // Limita a parte decimal a no máximo 2 dígitos
            if (parts.length === 2 && parts[1].length > 2) {
                newValue = parts[0] + '.' + parts[1].slice(0, 2);
            }
        }

        if (onChange) {
            onChange({ target: { name, value: newValue } });  // Passe o valor correto para onChange
        }
    };

    return (
        <div className={`${theme === 'dark' ? styles.dark : styles.light}`}>
            <div className={styles.container} style={{ borderRadius }}>
                {Icon && ( // Renderiza a div do ícone apenas se o ícone for passado
                    <div className={styles.icon}>
                        <Icon size={iconSize} color={iconColor} />
                    </div>
                )}
                <input
                    type={typeInput}
                    className={styles.input}
                    value={value}
                    onChange={handleChange}  // Usa a função handleChange corrigida
                    placeholder={placeholder}
                    name={name}
                    style={{ 
                        maxWidth, 
                        display: 'flex', 
                        alignItems: 'center',
                        borderRadius: Icon ? '0 10px 10px 0' : '10px',  // Define o border-radius condicional
                    }}
                    maxLength={maxLength}  // Define o número máximo de caracteres permitidos
                />
            </div>
        </div>
    );
};

export default Input;
