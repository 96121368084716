import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/sidebar';
import styles from './layout.module.css';
import { useTheme } from '../themecontext/ThemeContext';
import { HiOutlineMenu } from "react-icons/hi";

const App = ({ children }) => {
  const { theme } = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Estado para controlar a visibilidade do Sidebar
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200); // Verifica se é uma tela menor que 1200px

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Alterna a visibilidade do Sidebar
  };

  // Monitora o tamanho da tela e ajusta o estado
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setIsMobile(false); // Para telas maiores, o sidebar fica aberto
        setIsSidebarOpen(true); // Sidebar sempre aberto
      } else {
        setIsMobile(true); // Para telas menores, exibir o botão de abrir/fechar
        setIsSidebarOpen(false); // Sidebar fechado por padrão
      }
    };

    handleResize(); // Chamada inicial
    window.addEventListener('resize', handleResize); // Adiciona o listener para monitorar o redimensionamento da tela

    return () => window.removeEventListener('resize', handleResize); // Remove o listener ao desmontar o componente
  }, []);

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div className={styles.content}>
        {/* Botão de menu hamburguer para mobile */}
        {isMobile && (
          <HiOutlineMenu onClick={toggleSidebar} className={`${styles.hamburgerMenu} ${styles[theme]}`} size={24} />
        )}

        {/* Sidebar, passando o estado de visibilidade como prop */}
        <div className={styles.content_Sidebar}>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        </div>

        <div className={styles.container_Body}>
          <div className={styles.content_Body}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
