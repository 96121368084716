import React, { useState } from 'react';
import Popup from '../../../components/popup/popup';
import Input from '../../../components/input/input';
import customAxios from '../../../../../utils/customaxios';
import styles from './list.module.css'
import { IoAdd } from "react-icons/io5";

const App = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cnpj: '',
    company_name: ''
  });
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar o popup

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Verificação manual se todos os campos estão preenchidos
    if (!formData.name || !formData.email || !formData.cnpj || !formData.company_name) {
      alert('Por favor, preencha todos os campos!');
      return;
    }

    try {
      const response = await customAxios.post('https://client.rapidchargeback.com/api/app/company/create', formData);
      alert('Registro bem-sucedido!');
      setIsPopupOpen(false); // Fechar o popup após o registro bem-sucedido
    } catch (error) {
      alert('Erro ao registrar: ' + (error.response?.data || error.message));
    }
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <button style={{ display: 'flex' }} className={styles.button_popup} onClick={handleOpenPopup}><IoAdd size={20}/></button>
      
      <Popup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title="Registrar Empresa"
        firstChild={
          <div className={styles.grid}>
            <div className={styles.grid_item}>
              <h4>Nome</h4>
              <Input typeInput="text" name="name" placeholder="Nome da empresa" value={formData.name} onChange={handleChange} />
            </div>
            <div className={styles.grid_item}>
              <h4>Email</h4>
              <Input typeInput="email" name="email" placeholder="Email de contato da empresa" value={formData.email} onChange={handleChange} />
            </div>
            <div className={styles.grid_item}>
              <h4>CNPJ</h4>
              <Input typeInput="text" name="cnpj" placeholder="CNPJ da empresa" value={formData.cnpj} onChange={handleChange} />
            </div>
            <div className={styles.grid_item}>
              <h4>Razão social</h4>
              <Input typeInput="text" name="company_name" placeholder="Razão social da empresa" value={formData.company_name} onChange={handleChange} />
            </div>
          </div>
        }
        secondChild={
          <div className={styles.popup_footer}>
            <div/>
            <button onClick={handleSubmit}>Registrar</button>
          </div>
        }
      />
    </div>
  );
};

export default App;
