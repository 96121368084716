import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../themecontext/ThemeContext';
import styles from './select.module.css';
import { IoIosArrowUp } from "react-icons/io";

const App = ({ 
  options, 
  value, 
  onChange, 
  name, 
  disabled = false, 
  borderRadius = '10px', 
  maxWidth 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [openDirection, setOpenDirection] = useState('down'); // Estado para direção de abertura
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 417);  // Estado para monitorar largura da tela
  const selectRef = useRef(null);
  const optionsRef = useRef(null); // Referência para a lista de opções
  const { theme } = useTheme();

  useEffect(() => {
    const selectedOption = options.find(option => option.value === value);
    if (selectedOption) {
      setSelectedLabel(selectedOption.label);
    }
  }, [value, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      const selectRect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = optionsRef.current ? optionsRef.current.offsetHeight : 0;
      const spaceBelow = window.innerHeight - selectRect.bottom;
      const spaceAbove = selectRect.top;

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        setOpenDirection('up');
      } else {
        setOpenDirection('down');
      }
    }
  }, [isOpen]);

  // Atualiza o estado `isWideScreen` quando a tela é redimensionada
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 417);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedLabel(option.label);
    onChange({ target: { name, value: option.value } });
    setIsOpen(false);
  };

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`} ref={selectRef} style={{ maxWidth: isWideScreen ? maxWidth : '100%' }}>
      <div className={`${styles.select} ${disabled ? styles.disabled : ''}`} onClick={handleToggle} style={{ borderRadius }}>
        <div className={styles.text}>{selectedLabel || ''}</div>
        <span className={`${styles.arrow} ${isOpen ? (openDirection === 'down' ? styles.arrowDown : styles.arrowUp) : (openDirection === 'down' ? styles.arrowUp : styles.arrowDown)}`}>
          <IoIosArrowUp size={16} />
        </span>
      </div>
      {isOpen && (
        <ul className={styles.options} ref={optionsRef} style={{ borderRadius, top: openDirection === 'down' ? '100%' : 'auto', bottom: openDirection === 'up' ? '120%' : 'auto', }}>
          {options.map((option, index) => (
            <li key={index} className={styles.optionItem} onClick={() => handleSelect(option)}>{option.label}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default App;
