import React from 'react';
import styles from './upload.module.css'
import Header from '../../components/panel/panel'
import Upload from './components/upload'
import List from './components/list'

const App = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content_header}>
        <Header 
          title="Upload"
          description="Painel único para registrar novas informaçoes na banco de dados."
        />
      </div>
      <div>
        <h2>Registrar novas informaçoes</h2>
        <Upload />
        <List />
      </div>
    </div>
  );
};

export default App;
