import React, { useState, useEffect, useCallback, useMemo } from 'react';
import DatePicker from '../../../components/datapicker/datapicker';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { useTheme } from '../../../components/themecontext/ThemeContext';
import styles from './components.module.css'
import Select from '../../../components/select/select'
import { FiAlertTriangle } from "react-icons/fi";
import ReactDOMServer from 'react-dom/server';
import { LuSearchX, LuLoader2 } from "react-icons/lu";

// Hook personalizado para buscar métricas
const useFetchMetrics = (startDate, endDate, setOriginalMetrics) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!startDate || !endDate) return;

      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token'); // Substitua pelo seu token
        const response = await axios.get(
          'https://client.rapidchargeback.com/api/product/chargebackalert/dashboard/metrics/mastercard',
          {
            params: { startDate, endDate },
            headers: { Authorization: token },
          }
        );

        // Remove a hora de ArrivalDate (no formato "YYYY-MM-DD HH:MM:SS") e armazena os dados
        const processedData = response.data.map(item => {
        const dateOnly = item.ArrivalDate ? item.ArrivalDate.split('T')[0] : ''; // Remove a hora mantendo apenas a data
          return { ...item, ArrivalDate: dateOnly }; // Retorna o objeto com ArrivalDate modificado
        });

        setOriginalMetrics(processedData); // Armazena os dados originais da API
      } catch (err) {
        setError('Erro ao buscar métricas');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, setOriginalMetrics]);

  return { loading, error };
};

// Função para gerar todas as datas entre duas datas
const generateDateRange = (start, end) => {
  const dateArray = [];
  let currentDate = new Date(start);

  while (currentDate <= new Date(end)) {
    dateArray.push(new Date(currentDate)); // Adiciona a data como um objeto Date
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};

// Função para processar dados para o gráfico
const processChartData = (data, startDate, endDate, theme) => {
  const addOneDay = (dateStr) => {
    const date = new Date(dateStr);
    return date; // Retorna o objeto Date
  };

  const allDates = generateDateRange(startDate, endDate); // Gera todas as datas entre o início e o fim
  const dateDataMap = new Map(allDates.map(date => [date.getTime(), { 
    Total: 0, ACCOUNT_SUSPENDED: 0, OTHER: 0, NOTFOUND: 0, ACTIVE_DISPUTE: 0 
  }])); // Inicializa todas as datas com zeros

  // Preenche os dados disponíveis
  data.forEach(item => {
    const adjustedDate = addOneDay(item.ArrivalDate).getTime(); // Usa o timestamp
    if (dateDataMap.has(adjustedDate)) {
      const status = item.Status || 'ACTIVE_DISPUTE';
      dateDataMap.get(adjustedDate).Total += 1;
      if (status in dateDataMap.get(adjustedDate)) {
        dateDataMap.get(adjustedDate)[status] += 1;
      }
    }
  });

  // Prepara os dados para o gráfico
  const dates = Array.from(dateDataMap.keys());
  const totalAlerts = dates.map(date => dateDataMap.get(date).Total);
  const accountSuspended = dates.map(date => dateDataMap.get(date).ACCOUNT_SUSPENDED);
  const other = dates.map(date => dateDataMap.get(date).OTHER);
  const notFound = dates.map(date => dateDataMap.get(date).NOTFOUND);
  const activeDispute = dates.map(date => dateDataMap.get(date).ACTIVE_DISPUTE);

  // Configurações de tema para o gráfico
  const themeOptions = theme === 'dark' ? {
    foreColor: '#FFF'
  } : {
    foreColor: '#333'
  };

  return {
    series: [
      { name: 'Total', data: dates.map((date, i) => [date, totalAlerts[i]]) },
      { name: 'ACCOUNT_SUSPENDED', data: dates.map((date, i) => [date, accountSuspended[i]]) },
      { name: 'OTHER', data: dates.map((date, i) => [date, other[i]]) },
      { name: 'NOTFOUND', data: dates.map((date, i) => [date, notFound[i]]) },
      { name: 'ACTIVE_DISPUTE', data: dates.map((date, i) => [date, activeDispute[i]]) }
    ],
    options: {
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        toolbar: { show: false },
        background: 'transparent'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: themeOptions.foreColor // Cor dos labels do eixo X baseada no tema
          }
        }
      },
      yaxis: { 
        title: { 
          text: '', 
          style: { 
            color: themeOptions.foreColor // Cor do título do eixo Y
          } 
        },
        labels: {
          style: {
            colors: themeOptions.foreColor // Cor dos labels do eixo Y baseada no tema
          }
        }
      },
      dataLabels: { enabled: false },
      stroke: { curve: 'straight' },
      fill: {
        type: 'gradient', 
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.3,
          opacityFrom: 1,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      legend: { 
        show: false,
        labels: {
          colors: themeOptions.foreColor // Cor dos labels da legenda
        }
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          // Utilize o timestamp diretamente da série de dados para evitar mudanças de fuso horário
          const timestamp = w.globals.seriesX[seriesIndex][dataPointIndex];
          
          // Formate a data manualmente usando o timestamp original
          const dateISO = new Date(timestamp).toISOString().split('T')[0]; // Formato YYYY-MM-DD
      
          // Inverta o formato da data para DD-MM-YYYY
          const [year, month, day] = dateISO.split('-');
          const date = `${day}/${month}/${year}`; // Formato DD-MM-YYYY
          
          const total = series[0][dataPointIndex];
          const accountSuspended = series[1][dataPointIndex];
          const other = series[2][dataPointIndex];
          const notFound = series[3][dataPointIndex];
          const activeDispute = series[4][dataPointIndex];
          
          // Calcula a porcentagem de cada status com base no total, verificando se o total é 0
          const accountSuspendedPercent = total > 0 ? ((accountSuspended / total) * 100).toFixed(0) : '0';
          const otherPercent = total > 0 ? ((other / total) * 100).toFixed(0) : '0';
          const notFoundPercent = total > 0 ? ((notFound / total) * 100).toFixed(0) : '0';
          const activeDisputePercent = total > 0 ? ((activeDispute / total) * 100).toFixed(0) : '0';

           // Função para renderizar ícones como strings HTML usando ReactDOMServer
            const renderIcon = ReactDOMServer.renderToString(<FiAlertTriangle size={15}/>);
          
          // Crie o HTML para o tooltip personalizado usando estilos de CSS Modules
          return `<div class="${styles.tooltipCustom}">
                    <div class="${styles.tooltipHeader}">
                      <h3>${date}</h3>
                    </div>
                    <div class="${styles.tooltipBody}">
                      <div>
                        <div class="${styles.tooltipItem} ${styles.tooltipItem1}">
                          <span>${renderIcon}</span>
                          <p>Total:</p>
                        </div>
                        <span>${total}</span>
                      </div>
                      <div>
                        <div class="${styles.tooltipItem} ${styles.tooltipItem2}">
                          <span>${renderIcon}</span>
                          <p>ACCOUNT_SUSPENDED:</p>
                        </div>
                        <div>
                          <span>${accountSuspended}</span>
                          <small>(${accountSuspendedPercent}%)</small>
                        </div>
                      </div>
                      <div>
                        <div class="${styles.tooltipItem} ${styles.tooltipItem3}">
                          <span>${renderIcon}</span>
                          <p>OTHER:</p>
                        </div>
                        <div>
                          <span>${other}</span>
                          <small>(${otherPercent}%)</small>
                        </div>
                      </div>
                      <div>
                        <div class="${styles.tooltipItem} ${styles.tooltipItem4}">
                          <span>${renderIcon}</span>
                          <p>NOTFOUND:</p>
                        </div>
                        <div>
                          <span>${notFound}</span>
                          <small>(${notFoundPercent}%)</small>
                        </div>
                      </div>
                      <div>
                        <div class="${styles.tooltipItem} ${styles.tooltipItem5}">
                          <span>${renderIcon}</span>
                          <p>ACTIVE_DISPUTE:</p>
                        </div>
                        <div>
                          <span>${activeDispute}</span>
                          <small>(${activeDisputePercent}%)</small>
                        </div>
                      </div>
                    </div>
                  </div>`;
        },
        theme: theme
      },       
      colors: ['#6658f6', '#66d8bb', '#ffae42', '#ff6b6b', '#CCCCCC']
    }
  };
};

// Função para processar dados do Treemap
const processTreemapData = (data, field, theme) => {
  const groupedData = data.reduce((acc, item) => {
    let key = item[field];

    // Verifica se o valor é nulo ou vazio e o substitui por "Desconhecido"
    if (!key) key = "Desconhecido";

    // Se o campo for "CardNumber", usa apenas os primeiros 6 caracteres
    if (field === 'CardNumber') {
      key = key.slice(0, 6); // Extrai os 6 primeiros caracteres
    }

    if (!acc[key]) acc[key] = 0;
    acc[key] += 1;
    return acc;
  }, {});

  return {
    series: [{
      data: Object.keys(groupedData).map(key => ({
        x: key,
        y: groupedData[key]
      }))
    }],
    options: {
      chart: { type: 'treemap', height: 350, toolbar: { show: false } },
      title: { text: '' },
      colors: ['#6658f6'],
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false
        }
      },
      stroke: {
        width: 1
      },
      tooltip: {
        custom: function({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.globals.series[seriesIndex][dataPointIndex]; // Valor do dado atual
          const fieldName = w.config.series[seriesIndex].data[dataPointIndex].x; // Nome do dado (field)
      
          // Calcula o total de todos os valores no Treemap
          const total = w.globals.series[seriesIndex].reduce((acc, val) => acc + val, 0);
          
          // Calcula a porcentagem do valor atual em relação ao total
          const percentage = total > 0 ? ((data / total) * 100).toFixed(0) : '0';
      
          // Função para renderizar ícones como strings HTML usando ReactDOMServer
          const renderIcon = ReactDOMServer.renderToString(<FiAlertTriangle size={15} />);
          
          return `<div class="${styles.tooltipCustom}">
                    <div class="${styles.tooltipHeader} ${styles.tooltipHeader2}">
                      <h3>${fieldName}</h3> <!-- Usando o nome do dado (field) no header -->
                    </div>
                    <div class="${styles.tooltipBody} ${styles.tooltipBody2}">
                      <div>
                        <div class="${styles.tooltipItem}">
                          <span>Total de alertas:</span>
                        </div>
                        <div>
                          <span>${data}</span>
                          <small>(${percentage}%)</small>
                        </div>
                      </div>
                    </div>
                  </div>`;
        },
        theme: theme
      }         
    }
  };
};

// Função para processar dados fixos, considerando peculiaridades de campos como "Status"
const processFixedData = (data, field, expectedValues = [], emptyValueMapping = {}) => {
  const totalItems = data.length;
  
  // Inicializa o agrupamento de dados com todos os valores esperados
  const groupedData = expectedValues.reduce((acc, value) => {
    acc[value] = { count: 0, percentage: 0 };
    return acc;
  }, {});

  // Agrupa os dados reais
  data.forEach(item => {
    let key = item[field];

    // Verifica se o valor do campo está vazio e mapeia para o valor especificado
    if (!key && emptyValueMapping[field]) {
      key = emptyValueMapping[field];
    }

    if (groupedData[key] !== undefined) {
      groupedData[key].count += 1;
    }
  });

  // Calcula a porcentagem
  Object.keys(groupedData).forEach(key => {
    groupedData[key].percentage = totalItems > 0 ? ((groupedData[key].count / totalItems) * 100).toFixed(0) : '0';
  });

  return groupedData;
};

// Componente de gráfico separado
const ChartData = React.memo(({ data, startDate, endDate }) => {
  const { theme } = useTheme(); // Obtém o tema atual do contexto

  // Calcula as opções do gráfico com base no tema
  const chartData = useMemo(() => processChartData(data, startDate, endDate, theme), [data, startDate, endDate, theme]);

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={350}
    />
  );
});

// Componente de Treemap
const TreemapChart = ({ data, field, loading }) => {
  // Processa os dados do Treemap
  const treemapData = processTreemapData(data, field);

  // Verifica se há dados para serem exibidos no Treemap
  const hasData = treemapData.series[0].data.length > 0;

  return (
    <div>
      {loading ? (
        // Exibe o carregando enquanto o estado `loading` for verdadeiro
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <LuLoader2 size={30} className={styles.loadingIcon} />
          <p>Buscando alertas, por favor, aguarde...</p>
        </div>
      ) : hasData ? (
        // Exibe o gráfico se houver dados e o carregamento estiver concluído
        <Chart
          options={treemapData.options}
          series={treemapData.series}
          type="treemap"
          height={350}
        />
      ) : (
        // Exibe a mensagem de que não há alertas disponíveis se não houver dados
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <LuSearchX size={30} />
          <p>Nenhum alerta disponível para exibição.</p>
        </div>
      )}
    </div>
  );
};

// Componente para exibição de dados fixos
const FixedDataDisplay = ({ title, data, field, expectedValues, emptyValueMapping, Icon, iconColors = [], iconSize = 15 }) => {
  const fixedData = processFixedData(data, field, expectedValues, emptyValueMapping);

  return (
    <div className={styles.fixed_data_item}>
      <h3>{`Alertas por ${field}`}</h3>
      <div>
        {Object.keys(fixedData).map((key, index) => (
          <div key={key}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {Icon && (
                <Icon
                  color={iconColors[index] || 'gray'}
                  size={iconSize}
                />
              )}
              <p>{`${title || ''} ${key}: `}</p>
            </div>
            <div>
              <span>{fixedData[key].count}</span>
              <small>{`(${fixedData[key].percentage}%)`}</small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Função para processar valores únicos a partir dos dados da API
const getUniqueValues = (data, field) => {
  const uniqueValues = [...new Set(data.map(item => item[field]))];
  return uniqueValues;
};

// Componente de grid genérico para exibição de dados
const DataGridWithToggle = ({ data, field, descriptionFile, loading }) => {
  const [expandedItems, setExpandedItems] = useState(new Set()); // Armazena os itens expandidos
  const uniqueValues = getUniqueValues(data, field);

  // Calcular a contagem e a porcentagem de cada valor
  const totalItems = data.length;
  const valueCounts = uniqueValues.map((value) => {
    const count = data.filter((item) => item[field] === value).length;
    const percentage = ((count / totalItems) * 100).toFixed(0);
    return { value, count, percentage };
  });

  // Ordena o array valueCounts pela contagem (count) em ordem decrescente
  valueCounts.sort((a, b) => b.count - a.count);

  // Alterna o estado de expansão do item ao clicar
  const handleItemClick = (value) => {
    setExpandedItems((prevExpandedItems) => {
      const newExpandedItems = new Set(prevExpandedItems);
      if (newExpandedItems.has(value)) {
        newExpandedItems.delete(value); // Remove do conjunto se já estiver presente (colapsa)
      } else {
        newExpandedItems.add(value); // Adiciona ao conjunto se não estiver presente (expande)
      }
      return newExpandedItems;
    });
  };

  // Verifica se há dados disponíveis para exibição
  const hasData = valueCounts.length > 0;

  return (
    <div>
      {loading ? (
        // Exibe a mensagem de carregamento quando `loading` for verdadeiro
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <LuLoader2 size={30} className={styles.loadingIcon} />
          <p>Buscando alertas, por favor, aguarde...</p>
        </div>
      ) : hasData ? (
        <div className={styles.data_grid}>
          {valueCounts.map(({ value, count, percentage }) => {
            const isExpanded = expandedItems.has(value); // Verifica se o item está expandido
            const itemInfo = descriptionFile.find((item) => String(item.MCC) === String(value));

            return (
              <div key={value} className={styles.data_item} onClick={() => handleItemClick(value)}>
                <div style={{ display: 'flex', justifyContent: 'space-between', userSelect: 'none' }}>
                  <span>{field} {value}:</span>
                  <div>
                    <span>{count}</span>
                    <small>({percentage}%)</small>
                  </div>
                </div>
                {isExpanded && ( // Se estiver expandido, exibe a descrição
                  <div className={styles.item_description}>
                    {itemInfo ? itemInfo.Descrição : 'Descrição não encontrada'}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        // Exibe a mensagem de que não há dados disponíveis se o `loading` for falso e não houver dados
        <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          <LuSearchX size={30}/>
          <p>Nenhum alerta disponível para exibição.</p>
        </div>
      )}
    </div>
  );
};

// Componente de Filtro Reutilizável
const FilterComponent = ({ data, field, options, onFilterChange, label }) => {
  const [selectedOption, setSelectedOption] = useState('Todas empresas');

  const handleChange = (e) => {
    const value = e.target.value; // Obtém o valor selecionado
    setSelectedOption(value);

    // Lógica de Filtragem
    const filteredData = value === 'Todas empresas'
      ? data
      : data.filter((item) => item[field]?.startsWith(value) &&
        (item[field] === value || [' ', '-', '_'].includes(item[field][value.length])));

    onFilterChange(filteredData, value); // Passa o filtro atual para o callback
  };

  // Cria uma lista de opções no formato esperado pelo CustomSelect
  const formattedOptions = options.map((option) => ({ value: option, label: option }));

  return (
    <div>
      {label && <label>{label}: </label>}
      <Select
        options={formattedOptions}
        value={selectedOption}
        onChange={handleChange}
        name="filter"
        maxWidth='200px'
      />
    </div>
  );
};

// Função para obter opções de filtro do token no localStorage
const getFilterOptionsFromToken = () => {
  const token = localStorage.getItem('token');
  if (!token) return [];

  try {
    // Divide o token para obter o payload (segunda parte)
    const base64Payload = token.split('.')[1];
    
    // Decodifica a string Base64 para JSON
    const jsonPayload = JSON.parse(atob(base64Payload));

    // Extrai os valores das propriedades que começam com "merchant_mastercard_"
    const filterValues = Object.keys(jsonPayload)
      .filter((key) => key.startsWith('merchant_mastercard_')) // Filtra chaves que começam com "merchant_mastercard_"
      .map((key) => jsonPayload[key]); // Pega o valor da chave

    // Retorna as opções de filtro incluindo "Todas empresas"
    return ['Todas empresas', ...filterValues];
  } catch (error) {
    // console.error('Erro ao decodificar o token:', error);
    return [];
  }
};

// Componente principal
const App = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [originalMetrics, setOriginalMetrics] = useState([]); // Estado para dados originais
  const [filteredMetrics, setFilteredMetrics] = useState([]); // Estado para dados filtrados
  const [activeTreemap, setActiveTreemap] = useState('MerchantDescriptor'); // Estado para o treemap ativo
  const [selectedFilter, setSelectedFilter] = useState('Todas empresas'); // Estado para o filtro de prioridade 2
  const { theme } = useTheme(); // Obtém o tema atual do contexto

  const { loading, error } = useFetchMetrics(startDate, endDate, setOriginalMetrics);

  // Atualiza `filteredMetrics` quando `originalMetrics` ou `selectedFilter` mudam
  useEffect(() => {
    if (selectedFilter === 'Todas empresas') {
      setFilteredMetrics(originalMetrics); // Sem filtro de prioridade 2
    } else {
      // Aplica o filtro de prioridade 2 com insensibilidade a maiúsculas e minúsculas
      const filteredData = originalMetrics.filter((item) => {
        const merchantName = item.Merchant ? item.Merchant.toLowerCase() : '';
        const filterValue = selectedFilter.toLowerCase();
        return merchantName.startsWith(filterValue) &&
          (merchantName === filterValue || [' ', '-', '_'].includes(merchantName[filterValue.length]));
      });
      setFilteredMetrics(filteredData);
    }
  }, [originalMetrics, selectedFilter]);  

  const handleDateChange = useCallback((start, end) => {
    setStartDate(start);
    setEndDate(end);
  }, []);

  // Função de callback para quando o filtro de prioridade 2 mudar
  const handleFilterChange = (filteredData, filterValue) => {
    setFilteredMetrics(filteredData);
    setSelectedFilter(filterValue); // Armazena a opção selecionada
  };

  // Opções de filtro para o campo `Merchant`
  const descriptorFilterOptions = getFilterOptionsFromToken();

  // Opções para o seletor de treemap
  const treemapOptions = [
    { value: 'Issuer', label: 'Emissora' },
    { value: 'Country', label: 'País' },
    { value: 'CardNumber', label: 'Bin' },
    { value: 'MerchantDescriptor', label: 'Comerciante' },
  ];

  const totalDataCount = filteredMetrics.length; // Total de dados filtrados

  // Calcula o número de dias entre as datas selecionadas
  const selectedDays = startDate && endDate ? (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) + 1 : 0;
  const dailyAverage = selectedDays > 0 ? Math.round(totalDataCount / selectedDays) : 0; // Média diária arredondada

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.filter_container}>
        <div>
          <DatePicker onDateChange={handleDateChange} />
        </div>
        <div style={{ width: '100%' }}>
          <FilterComponent
            data={originalMetrics}
            field="Merchant"
            options={descriptorFilterOptions}
            onFilterChange={handleFilterChange}
            label=""
          />
        </div>
      </div>
      <div className={styles.info_container}>
        <div className={styles.info_item}>
          <h3>Total de Alertas</h3>
          <span>{totalDataCount}</span>
        </div>
        <div className={styles.info_item}>
          <h3>Média diária de Alertas</h3>
          <span>{dailyAverage}</span>
        </div>
      </div>
      <div className={styles.chart_container}>
        <div className={styles.chart_header}>
          <h3>Alertas por Dia</h3>
        </div>
        <div className={styles.chart_content} style={{ marginLeft: '-17px' }}>
          <ChartData data={filteredMetrics} startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <div className={styles.chart_container}>
        <div className={styles.chart_header}>
          <h3>Alertas por:</h3>
          <Select
            options={treemapOptions}
            value={activeTreemap}
            onChange={(e) => setActiveTreemap(e.target.value)}
            name="treemapSelect"
            maxWidth='150px'
          />
        </div>
        <div className={styles.chart_content}>
          {activeTreemap === 'Issuer' && <TreemapChart data={filteredMetrics} field="Issuer" loading={loading} />}
          {activeTreemap === 'Country' && <TreemapChart data={filteredMetrics} field="Country" loading={loading} />}
          {activeTreemap === 'CardNumber' && <TreemapChart data={filteredMetrics} field="CardNumber" loading={loading} />}
          {activeTreemap === 'MerchantDescriptor' && <TreemapChart data={filteredMetrics} field="MerchantDescriptor" loading={loading} />}
        </div>
      </div>
      <div className={styles.fixed_data_container}>
        <div>
          <FixedDataDisplay 
            data={filteredMetrics}
            field="Status"
            expectedValues={['ACCOUNT_SUSPENDED', 'OTHER', 'NOTFOUND', 'ACTIVE_DISPUTE']} 
            emptyValueMapping={{ Status: 'ACTIVE_DISPUTE' }}
            Icon={FiAlertTriangle}
            iconColors={['#66d8bb', '#ffae42', '#ff6b6b', '#CCCCCC']}
            iconSize={15}
          />
        </div>
      </div>
    </div>
  );
};

export default App;

