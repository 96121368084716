import React from 'react';
import styles from './popup.module.css';
import { CgClose } from "react-icons/cg";
import { useTheme } from '../themecontext/ThemeContext';

const Popup = ({ isOpen, onClose, firstChild, secondChild, maxWidth = '500px', title }) => {
  const { theme } = useTheme();

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
        <div className={`${styles.content} ${theme === 'dark' ? styles.dark : styles.light}`} style={{ maxWidth: maxWidth }}>
            {/* Primeira div para o botão de fechar */}
            <div className={styles.header}>
                <h3>{title}</h3>
                <button className={styles.button} onClick={onClose}><CgClose size={20} /></button>
            </div>

            {/* Segunda div para o primeiro conteúdo */}
            <div className={styles.body}>
                {firstChild}
            </div>

            {/* Terceira div para o segundo conteúdo */}
            <div className={styles.footer}>
                {secondChild}
            </div>
        </div>
    </div>
  );
};

export default Popup;
