import React, { useState } from 'react';
import { SlMenu } from "react-icons/sl";
import { CgClose } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import Logo from "../../../images/platform/Logo.svg";

const Overlay = ({ onClose, isMenuOpen }) => (
  <div className={`${styles.overlay} ${isMenuOpen ? styles.active : ''}`} onClick={onClose}></div>
);

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isHovered0, setIsHovered0] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleSobrenosClick = () => {
    const contatoSection = document.getElementById('Sobrenos');
    if (contatoSection) {
        contatoSection.scrollIntoView({ behavior: 'smooth' });
    } else {
        navigate('/');
        setTimeout(() => {
            const sobrenosSection = document.getElementById('Sobrenos');
            if (sobrenosSection) {
                sobrenosSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }
  };

  const handleContatoClick = () => {
      const contatoSection = document.getElementById('Contato');
      if (contatoSection) {
          contatoSection.scrollIntoView({ behavior: 'smooth' });
      } else {
          navigate('/');
          setTimeout(() => {
              const contatoSection = document.getElementById('Contato');
              if (contatoSection) {
                  contatoSection.scrollIntoView({ behavior: 'smooth' });
              }
          }, 300);
      }
  };

  const handleSolucoesClick = () => {
    const contatoSection = document.getElementById('Solucoes');
    if (contatoSection) {
        contatoSection.scrollIntoView({ behavior: 'smooth' });
    } else {
        navigate('/');
        setTimeout(() => {
            const contatoSection = document.getElementById('Solucoes');
            if (contatoSection) {
                contatoSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }
  };

  const handleDocumentacaoClick = () => {
    window.location.href = "https://rapidchargeback.gitbook.io/apis-rapidchargeback/";
  };

  return (
    <div className={styles.headerMobile}>
      <Overlay onClose={() => setMenuOpen(false)} isMenuOpen={isMenuOpen} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px', width: '100%' }}>
        <div className={styles.logo}>
          <img onClick={() => navigate('/')} src={Logo} alt="RapidChargeBack" draggable="false" />
        </div>
        <div className={styles.iconheadermob}>
          <SlMenu size={22} style={{ cursor: 'pointer' }} onClick={handleMenuToggle} />
        </div>
      </div>
      <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className={`${styles.sidemenu} ${isMenuOpen ? styles.open : ''}`}>
        <div style={{ position: 'absolute', right: '20px', top: '20px' }}>
          <CgClose color='black' size={24} style={{ cursor: 'pointer' }} onClick={handleMenuToggle} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '80px' }}>
          <img onClick={() => navigate('/')} src={Logo} alt="RapidChargeBack" draggable="false" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px' }}>
          <div>
            <p onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)} onClick={() => navigate('/app/auth/login')}>Login</p>
            <div className={`${styles.bar} ${isHovered1 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)} onClick={() => {handleContatoClick(); handleMenuToggle();}}>Contratar agora</p>
            <div className={`${styles.bar} ${isHovered2 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)} onClick={() => {handleSolucoesClick(); handleMenuToggle();}}>Soluções</p>
            <div className={`${styles.bar} ${isHovered3 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p onMouseEnter={() => setIsHovered4(true)} onMouseLeave={() => setIsHovered4(false)} onClick={() => {handleSobrenosClick(); handleMenuToggle();}}>Sobre Nós</p>
            <div className={`${styles.bar} ${isHovered4 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p onMouseEnter={() => setIsHovered5(true)} onMouseLeave={() => setIsHovered5(false)} onClick={handleDocumentacaoClick}>Documentação e recursos</p>
            <div className={`${styles.bar} ${isHovered5 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p onMouseEnter={() => setIsHovered0(true)} onMouseLeave={() => setIsHovered0(false)} onClick={() => navigate('/')}>Home</p>
            <div className={`${styles.bar} ${isHovered0 ? styles.barAnimation : ""}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopMenu = () => {
  const [isHovered0, setIsHovered0] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const navigate = useNavigate();

  const handleSobrenosClick = () => {
    const contatoSection = document.getElementById('Sobrenos');
    if (contatoSection) {
        contatoSection.scrollIntoView({ behavior: 'smooth' });
    } else {
        navigate('/');
        setTimeout(() => {
            const sobrenosSection = document.getElementById('Sobrenos');
            if (sobrenosSection) {
                sobrenosSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }
  };

  const handleContatoClick = () => {
      const contatoSection = document.getElementById('Contato');
      if (contatoSection) {
          contatoSection.scrollIntoView({ behavior: 'smooth' });
      } else {
          navigate('/');
          setTimeout(() => {
              const contatoSection = document.getElementById('Contato');
              if (contatoSection) {
                  contatoSection.scrollIntoView({ behavior: 'smooth' });
              }
          }, 300);
      }
  };

  const handleSolucoesClick = () => {
    const contatoSection = document.getElementById('Solucoes');
    if (contatoSection) {
        contatoSection.scrollIntoView({ behavior: 'smooth' });
    } else {
        navigate('/');
        setTimeout(() => {
            const contatoSection = document.getElementById('Solucoes');
            if (contatoSection) {
                contatoSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }
  };

  const handleDocumentacaoClick = () => {
    window.location.href = "https://rapidchargeback.gitbook.io/apis-rapidchargeback/";
  };

  return (
    <div className={styles.headerDesktop}>
      <img onClick={() => navigate('/')} src={Logo} alt="RapidChargeBack" draggable="false" style={{ userSelect: 'none' }} />
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '40px' }}>
        <div className={styles.headerDesktopList}>
          <div>
            <p className={styles.paragraphblackmini} onMouseEnter={() => setIsHovered0(true)} onMouseLeave={() => setIsHovered0(false)} onClick={() => navigate('/')}>Home</p>
            <div className={`${styles.bar} ${isHovered0 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p className={styles.paragraphblackmini} onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)} onClick={handleSolucoesClick}>Soluções</p>
            <div className={`${styles.bar} ${isHovered1 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p className={styles.paragraphblackmini} onMouseEnter={() => setIsHovered2(true)} onMouseLeave={() => setIsHovered2(false)} onClick={handleSobrenosClick}>Sobre Nós</p>
            <div className={`${styles.bar} ${isHovered2 ? styles.barAnimation : ""}`} />
          </div>
          <div>
            <p className={styles.paragraphblackmini} onMouseEnter={() => setIsHovered3(true)} onMouseLeave={() => setIsHovered3(false)} onClick={handleDocumentacaoClick}>Documentação e recursos</p>
            <div className={`${styles.bar} ${isHovered3 ? styles.barAnimation : ""}`} />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
          <button className={styles.Contratarbutton} onClick={handleContatoClick}>Contratar agora</button>
          <button className={styles.loginbutton} onClick={() => navigate('/app/auth/login')}>Login</button>
        </div>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div>
      <div className={styles.headerDesktopContainer}>
        <DesktopMenu/>
      </div>
      <div className={styles.headerMobileContainer}>
        <MobileMenu/>
      </div>
    </div>
  );
};

export default Header;