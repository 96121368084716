import React, { useMemo, useState } from 'react';
import styles from './infopopup.module.css';
import Popup from '../popup/popup';
import { FaList } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline, IoMdRefreshCircle, IoMdTime, IoMdStats } from 'react-icons/io'; // Importação do ícone IoMdStats
import { useTheme } from '../themecontext/ThemeContext';

const App = ({ data, fieldName, popupTitle, iconMapping, maxWidth = '400px' }) => {
  const { theme } = useTheme();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const { dataCounts, totalCount } = useMemo(() => {
    const counts = {};
    let total = 0;

    // Inicializa contadores para cada valor esperado
    Object.keys(iconMapping).forEach((key) => {
      counts[key] = 0;
    });

    // Calcula os contadores com base nos dados passados e no campo relevante
    data.forEach((item) => {
      const dataName = item[fieldName];
      if (dataName && counts.hasOwnProperty(dataName)) {
        counts[dataName]++;
        total++; // Incrementa o total de itens válidos
      }
    });

    return { dataCounts: counts, totalCount: total };
  }, [data, fieldName, iconMapping]);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className={`${styles.container} ${theme === 'dark' ? styles.dark : styles.light}`}>
      <div className={styles.info_container}>
        <div className={styles.info_item} onClick={handleOpenPopup}>
          <FaList style={{ display: 'flex' }} size={16}/>
          <h3>{popupTitle}</h3>
        </div>
      </div>
      <Popup
        isOpen={isPopupOpen}
        onClose={handleClosePopup}
        title={popupTitle}
        firstChild={
          <div className={styles.status_list}>
            <div key="total">
              <p><IoMdStats size={20} /> Total:</p>
              <span>{totalCount}</span>
            </div>
            {Object.entries(iconMapping).map(([key, Icon]) => {
              const count = dataCounts[key];
              const percentage = totalCount > 0 ? ((count / totalCount) * 100).toFixed(2) : 0; // Calcula a porcentagem
              return (
                <div key={key}>
                  <p><Icon size={20} /> {key}:</p>
                  <span>{count} <small>({percentage}%)</small></span>
                </div>
              );
            })}
          </div>
        }
        secondChild={null}
        maxWidth={maxWidth}
      />
    </div>
  );
};

export default App;
